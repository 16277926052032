import { OfflineDisabledReason } from './OfflineDisabledReason';
import { getItem, setItem } from 'owa-local-storage';
import { logUsage } from 'owa-analytics';

import { OFFLINE_DISABLED_REASON_KEY } from './constants';

// Track the reason we are disabling offline (e.g. out of disk space) so we can
// communicate the reason to the user.
export function setOfflineDisabledReason(reason: OfflineDisabledReason) {
    const previous = getOfflineDisabledReason();
    if (previous !== reason) {
        logUsage('offlineDisabledReason', { reason });
        setItem(self, OFFLINE_DISABLED_REASON_KEY, OfflineDisabledReason[reason]);
    }
}

export function getOfflineDisabledReason(): OfflineDisabledReason | undefined {
    const reason = getItem(self, OFFLINE_DISABLED_REASON_KEY);
    return reason ? OfflineDisabledReason[reason as OfflineDisabledReason] : undefined;
}
