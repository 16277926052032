import { logUsage } from 'owa-analytics';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import type { NotificationSubscription } from '../index';
import { type ChannelState } from '../schema/NotificationManagerStore';
import { type OwaDate, now, differenceInSeconds } from 'owa-datetime';
import type NotificationPayloadBase from 'owa-service/lib/contract/NotificationPayloadBase';
import type HierarchyNotificationPayload from 'owa-service/lib/contract/HierarchyNotificationPayload';
import type RowNotificationPayload from 'owa-service/lib/contract/RowNotificationPayload';
import type CalendarItemNotificationPayload from 'owa-service/lib/contract/CalendarItemNotificationPayload';

interface ComparedLogging {
    gatewayNotificationTime: OwaDate;
    substrateNotificationTime: OwaDate;
}

class NotificationLog<T> {
    public countNotificationData: Map<string, number> = new Map<string, number>();
    public countConnectionErrorStatus: Map<string, number> = new Map<string, number>();
    public countSubscriptionId: Map<string, Map<string, number>> = new Map<
        string,
        Map<string, number>
    >();
    public totalNotificationCount: Map<string, number> = new Map<string, number>();
    public subscriptionIdToNotificationType: Map<string, string> = new Map<string, string>();
    public mailboxToIgnoreForComparedLogging: Set<string> = new Set<string>();
    // comparing data properties for 2 channels
    public connectionDuration: Map<T, number> = new Map<T, number>(); // Logs the duration for channelstate.
    public deliveredNotificationData: Map<string, ComparedLogging> = new Map<
        string,
        ComparedLogging
    >(); // deliverednotificationdata for each key which is each notifications unique key
    public notificationData: Map<string, OwaDate> = new Map<string, OwaDate>(); //Notification data stored temporarily before being put in delivered notificationdata when both channel deliver the notification
    public countDisconnectedFromState: Map<string, number> = new Map<string, number>(); //number of times the connection was disconnected
    public countStateChange: Map<string, number> = new Map<string, number>(); // number of times the state change happened
    public lastState: T | ChannelState = 0;
    public lastStateChangeTime: OwaDate = now();
}

class NotificationConnectionLogger {
    private logIntervalId: ReturnType<typeof setInterval> | undefined = undefined;
    private notificationLog: NotificationLog<string> = new NotificationLog<string>();
    private diagnosticLogSummary: Map<string | number, number>[] = [];
    public constructor() {
        this.init();
    }

    public stopLog() {
        if (this.logIntervalId) {
            clearInterval(this.logIntervalId);
        }
    }

    public startLogTimer() {
        if (!this.logIntervalId) {
            this.logIntervalId = setInterval(this.doLog.bind(this), 60000 * 10); // log every 10 minutes
        }
    }

    private init() {
        this.notificationLog.countNotificationData.clear();
        this.notificationLog.countConnectionErrorStatus.clear();
        this.notificationLog.countDisconnectedFromState.clear();
        this.notificationLog.countStateChange.clear();
        this.notificationLog.connectionDuration.clear();
        this.notificationLog.countDisconnectedFromState.clear();
        this.notificationLog.totalNotificationCount.clear();
        this.diagnosticLogSummary.push(new Map<string | number, number>());
        // keep the length of diagnostic logsummary to 3 items which is 30 minutes of data.
        if (this.diagnosticLogSummary.length > 4) {
            this.diagnosticLogSummary.shift();
        }
    }

    private logMap(map: Map<string | number, number>, eventName: string) {
        const data: {
            [k: string | number]: number;
        } = {};
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        map.forEach((count, key) => {
            data[key] = count;
        });

        if (map.size > 0) {
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logUsage(eventName, data);
        }
    }

    private doLog() {
        this.logMap(this.notificationLog.countNotificationData, 'SignalR-PendingGetNotification');
        this.logMap(
            this.notificationLog.countConnectionErrorStatus,
            'SignalR-ConnectionErrorStatus'
        );
        this.logMap(
            this.notificationLog.countDisconnectedFromState,
            'SignalR-DisconnectedFromState'
        );
        this.logMap(this.notificationLog.countStateChange, 'SignalR-StateChange');
        this.logDeliveredNotifications();
        // log connection states duration time, Add diagnostic information which is pushed to ods if user sends feedback with diagnostic data.
        this.logConnectionStatesDurationTime();
        this.addDiagnosticDataSummary(this.notificationLog.countConnectionErrorStatus);
        this.init();
    }

    // this will log the connnection duration in respective states
    public logConnectionStatesDurationTime() {
        if (this.notificationLog.lastState && this.notificationLog.lastStateChangeTime) {
            const connectionDuration = differenceInSeconds(
                now(),
                this.notificationLog.lastStateChangeTime
            );
            this.notificationLog.lastStateChangeTime = now();
            const lastState = this.notificationLog.lastState.toString();
            this.notificationLog.connectionDuration.set(
                lastState,
                (this.notificationLog.connectionDuration.get(lastState) ?? 0) + connectionDuration
            );
        }
        this.addDiagnosticDataSummary(this.notificationLog.connectionDuration);
        this.logMap(this.notificationLog.connectionDuration, 'SignalR-ConnectionDuration');
    }

    public getDiagnosticDataSummary(): string {
        // Do the logging for the latest diagnostic data before returning the summary.
        this.doLog();
        const flattenedMap = this.diagnosticLogSummary.reduce((flattedMap, map) => {
            /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
             * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
            map.forEach((count, key) => {
                flattedMap.set(key, (flattedMap.get(key) ?? 0) + count);
            });
            return flattedMap;
        }, new Map());
        return Array.from(flattenedMap).reduce((summary, [key, value]) => {
            return summary + `${key}:${value},`;
        }, '');
    }

    private addDiagnosticDataSummary(map: Map<string | number, number>) {
        const lastDiagnosticLogSummary =
            this.diagnosticLogSummary.pop() ?? new Map<string | number, number>();
        const mergedMap = new Map([...lastDiagnosticLogSummary, ...map]);
        this.diagnosticLogSummary.push(mergedMap);
    }

    private logDeliveredNotifications() {
        const countToBeLogged = new Map<string, number>();
        const folderBasedCountToBeLogged = new Map<string, number>();
        for (const [subscriptionId, countOfFolders] of this.notificationLog.countSubscriptionId) {
            const notificationType =
                this.notificationLog.subscriptionIdToNotificationType.get(subscriptionId);
            let totalCount = 0;
            /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
             * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
            countOfFolders.forEach((folderCount, folderName) => {
                if (notificationType) {
                    folderBasedCountToBeLogged.set(
                        notificationType + '-' + folderName,
                        folderCount
                    );
                }
                totalCount += folderCount;
            });

            if (notificationType) {
                countToBeLogged.set(
                    notificationType,
                    (countToBeLogged.get(notificationType) ?? 0) + totalCount
                );

                this.notificationLog.countSubscriptionId.delete(subscriptionId);
            }
        }
        this.addDiagnosticDataSummary(countToBeLogged);
        this.logMap(
            new Map([...countToBeLogged, ...folderBasedCountToBeLogged]),
            'SignalR-DeliveredNotificationByType'
        );
    }

    public logPendingGetNotificationData(data: string) {
        this.notificationLog.countNotificationData.set(
            data,
            (this.notificationLog.countNotificationData.get(data) ?? 0) + 1
        );
    }

    public logConnectionErrorStatus(errorStatus: string) {
        this.notificationLog.countConnectionErrorStatus.set(
            errorStatus,
            (this.notificationLog.countConnectionErrorStatus.get(errorStatus) ?? 0) + 1
        );
    }

    private getChannelStateString(state: ChannelState) {
        switch (state) {
            case 1:
                return 'Initialized';
            case 2:
                return 'Ready';
            case 3:
                return 'Disconnected';
            case 4:
                return 'Reconnecting';
        }

        return 'Uninitialized';
    }

    /**
     * delegate function called from notification manager when the channel is disconnected
     * @param fromState: connection state from which the channel disconnected
     */
    public logSubstrateDisconnectedFromState(fromState: ChannelState) {
        const state = this.getChannelStateString(fromState);
        // Log the number of times the channel was disconnected
        this.notificationLog.countDisconnectedFromState.set(
            state,
            (this.notificationLog.countDisconnectedFromState.get(state) ?? 0) + 1
        );
        this.logSubstrateConnectionStateTimes(fromState, 3);
    }

    /**
     * logs the connection time for the respective channel in substrate
     * @param fromState - last state fetched from the manager config. As its shared the Channelstate is reused for gateway
     * @param toState - current state of the channel
     */
    public logSubstrateConnectionStateTimes(fromState: ChannelState, toState: ChannelState) {
        if (this.notificationLog.lastState && this.notificationLog.lastStateChangeTime) {
            const stateDuration =
                this.notificationLog.connectionDuration.get(
                    this.getChannelStateString(fromState)
                ) ?? 0;
            this.notificationLog.connectionDuration.set(
                this.getChannelStateString(fromState),
                stateDuration + differenceInSeconds(now(), this.notificationLog.lastStateChangeTime)
            );
        }
        this.notificationLog.lastStateChangeTime = now();
        this.notificationLog.lastState = this.getChannelStateString(toState);
    }

    private getSignalRStateString(state: number) {
        switch (state) {
            case 0:
                return 'Connecting';
            case 1:
                return 'Connected';
            case 2:
                return 'Reconnecting';
            case 4:
                return 'Disconnected';
        }

        return state.toString();
    }

    // We need to transform the signalR state to the channel state
    private getChannelState(signalRState: number) {
        switch (signalRState) {
            case 0:
                return 1;
            case 1:
                return 2;
            case 2:
                return 4;
            case 4:
                return 3;
        }
        return 0;
    }

    /**
     * logs the connection state change information for substrate channel
     * @param signalRState - current state of the channel in signalR.connectionState
     * @param channelState - last state fetched from the manager config. As its shared the Channelstate is reused for gateway
     */
    public logSubstrateConnectionStateChange(signalRState: number, channelState: ChannelState) {
        const newSignalRStateInString = this.getSignalRStateString(signalRState);
        const state = `signalRNewState: ${newSignalRStateInString}, channelState: ${channelState}`;
        //log total disconnected time
        this.logSubstrateConnectionStateTimes(
            channelState /* fromState */,
            this.getChannelState(signalRState) /* toState */
        );
        this.notificationLog.countStateChange.set(
            state,
            (this.notificationLog.countStateChange.get(state) ?? 0) + 1
        );
    }

    public buildNotificationTypeMap(subscription: NotificationSubscription) {
        if (subscription.subscriptionParameters.NotificationType) {
            this.notificationLog.subscriptionIdToNotificationType.set(
                subscription.subscriptionId,
                subscription.subscriptionParameters.NotificationType
            );
        }
    }

    public logDeliveredNotification(
        notification: NotificationPayloadBase,
        commonFolderIds?: Map<string, string>
    ) {
        let folderName = 'otherFolders';
        if (notification.id === 'HierarchyNotification') {
            //HierarchyNotification
            const notificationPayload = notification as HierarchyNotificationPayload;
            folderName = commonFolderIds?.get(notificationPayload.folderId ?? '') ?? 'otherFolders';
        } else if (notification.id?.includes('folderId')) {
            //RowNotification
            const notificationPayload = notification as RowNotificationPayload;
            folderName = commonFolderIds?.get(notificationPayload.FolderId ?? '') ?? 'otherFolders';
        } else if (notification.id?.includes('CalendarItemNotification')) {
            // CalendarItemNotification
            const notificationPayload = notification as CalendarItemNotificationPayload;
            folderName = commonFolderIds?.get(notificationPayload.FolderId ?? '') ?? 'otherFolders';
        }
        if (notification.id) {
            const folderNameToCount =
                this.notificationLog.countSubscriptionId.get(notification.id) ??
                new Map<string, number>();
            folderNameToCount.set(folderName, (folderNameToCount.get(folderName) ?? 0) + 1);
            this.notificationLog.countSubscriptionId.set(notification.id, folderNameToCount);
        }
    }
}

const logger = new NotificationConnectionLogger();

export function getNotificationConnectionLogger() {
    logger.startLogTimer();
    return logger;
}
