import type { NotificationManagerWorkerCallbacks } from 'owa-data-worker-utils';
import { setSubscribeCb } from 'owa-notification-manager/lib/subscribe';
import { setUnsubscribeCb } from 'owa-notification-manager/lib/unsubscribe';
import { setGetChannelReadyCb, setChannelId } from 'owa-notification-globals';
import { setNotificationEmitter } from 'owa-notification-manager/lib/notificationEmitter';
import { trace } from 'owa-trace';
import { NotificationEventType } from 'owa-notification-manager';
import type { NotificationCallback, NotificationSubscription } from 'owa-notification-manager';
import type NotificationPayloadBase from 'owa-service/lib/contract/NotificationPayloadBase';
import EventEmitter from 'owa-event-emitter';
import type { MailboxInfo } from 'owa-client-types';

// Convert back and forth between NotificationCallback and id so we can use a single proxy to notify subscriptions.
let nextNotificationCallbackId = 0;
const callbacks: Map<number, NotificationCallback> = new Map();

// Proxy back to the main thread since local subscriptions use signalR, which is tightly coupled to the window object
export function setNotificationCallbacks(
    notificationCallbacks: NotificationManagerWorkerCallbacks,
    channelId: string
) {
    setSubscribeCb((subscription: NotificationSubscription, callback: NotificationCallback) => {
        const callbackId = nextNotificationCallbackId++;
        callbacks.set(callbackId, callback);
        notificationCallbacks.subscribe(subscription, callbackId);
    });
    setUnsubscribeCb((subscription: NotificationSubscription, callback: NotificationCallback) => {
        let callbackId: number | undefined;
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        callbacks.forEach((cb, id) => {
            if (cb === callback) {
                callbackId = id;
            }
        });
        if (callbackId === undefined) {
            trace.warn('worker unsubscribe cant find callback');
            return;
        }
        callbacks.delete(callbackId);
        notificationCallbacks.unsubscribe(subscription, callbackId);
    });
    setGetChannelReadyCb(notificationCallbacks.getChannelReady);
    setChannelId(channelId);

    // Create an EventEmitter that forwards notification events back to the main thread notification emitter
    const emitter = new EventEmitter();
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    Object.keys(NotificationEventType).forEach(eventName =>
        emitter.on(eventName, (...data: any[]) =>
            notificationCallbacks.emitNotificationEvent(eventName, ...data)
        )
    );
    setNotificationEmitter(emitter);
}

export const notifySubscription = (
    notification: NotificationPayloadBase,
    notificationCallbackId: number,
    mailboxInfo: MailboxInfo
) => {
    const callback = callbacks.get(notificationCallbackId);
    if (!callback) {
        trace.warn(`worker notify can't find callback ${notificationCallbackId}`);
        return;
    }
    callback(notification, mailboxInfo);
};
