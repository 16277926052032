import type { Getter } from 'owa-bundling-light';
import { LazyModule, LazyAction } from 'owa-bundling-light';
import type {
    GraphQLResolverFn,
    GraphQLObserverFn,
    GraphQLResolverWithObserver,
    ResolverContext,
} from 'owa-graph-schema';
import { createLazyResolver } from './createLazyResolver';

function createLazyObserver<TModule, TResult, TParent, TContext, TArgs>(
    importCbOrlazyModule: (() => Promise<TModule>) | LazyModule<TModule>,
    getter: Getter<GraphQLObserverFn<TResult, TParent, TContext, TArgs>, TModule>
): GraphQLObserverFn<TResult, TParent, TContext, TArgs> {
    const lazyModule =
        typeof importCbOrlazyModule === 'function'
            ? new LazyModule(importCbOrlazyModule)
            : importCbOrlazyModule;
    const lazyAction = new LazyAction(lazyModule, getter);
    return lazyAction.importAndExecute;
}

export function createLazyResolverWithObserver<TModule, TResult, TParent, TContext, TArgs>(
    resolverName: string,
    importCbOrlazyModule: (() => Promise<TModule>) | LazyModule<TModule>,
    getResolver: Getter<GraphQLResolverFn<TResult, TParent, TContext, TArgs>, TModule>,
    getObserver: Getter<GraphQLObserverFn<TResult, TParent, TContext, TArgs>, TModule>,
    fallbackBehavior: ResolverContext['fallbackBehavior'] = 'Fallback'
): GraphQLResolverWithObserver<TResult, TParent, TContext, TArgs> {
    return {
        resolve: createLazyResolver(
            resolverName,
            importCbOrlazyModule,
            getResolver,
            fallbackBehavior
        ),
        observe: createLazyObserver(importCbOrlazyModule, getObserver),
    } as GraphQLResolverWithObserver<TResult, TParent, TContext, TArgs>;
}
