import type NotificationSubscription from './schema/NotificationSubscription';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { ConnectionType } from './HubConnection/utils/ConnectionType';

type BatchState = {
    subscriptions: NotificationSubscription[];
    pendingSubmission: boolean;
};

export function getBatchKeyForMailbox(mailboxInfo: MailboxInfo, connectionType: ConnectionType) {
    return getIndexerValueForMailboxInfo(mailboxInfo) + '_' + connectionType;
}

const batchStates: Map<string, BatchState> = new Map();

function getBatchState(mailboxInfo: MailboxInfo, connectionType: ConnectionType) {
    let batchState = batchStates.get(getBatchKeyForMailbox(mailboxInfo, connectionType));
    if (!batchState) {
        batchState = {
            subscriptions: [],
            pendingSubmission: false,
        };
        batchStates.set(getBatchKeyForMailbox(mailboxInfo, connectionType), batchState);
    }

    return batchState;
}

export const getBatch = (mailboxInfo: MailboxInfo, connectionType: ConnectionType) =>
    getBatchState(mailboxInfo, connectionType).subscriptions;
export const addToBatch = (
    mailboxInfo: MailboxInfo,
    subscription: NotificationSubscription,
    connectionType: ConnectionType
) => getBatch(mailboxInfo, connectionType).push(subscription);
export const clearBatch = (mailboxInfo: MailboxInfo, connectionType: ConnectionType) => {
    const batchState = getBatchState(mailboxInfo, connectionType);
    batchState.subscriptions = [];
};

export const setPending = (
    mailboxInfo: MailboxInfo,
    pending: boolean,
    connectionType: ConnectionType
) => {
    const batchState = getBatchState(mailboxInfo, connectionType);
    batchState.pendingSubmission = pending;
};
export const isPending = (mailboxInfo: MailboxInfo, connectionType: ConnectionType) =>
    getBatchState(mailboxInfo, connectionType).pendingSubmission;

export const clearStateForTests = () => batchStates.clear();
