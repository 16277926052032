import { loadOfflineSettingsFromDatabase } from 'owa-offline-sync-settings-store/lib/loadOfflineSettingsFromDatabase';
import { logUsage } from 'owa-analytics';
import type { AppDatabase } from 'owa-offline-database';

export async function tryLogDatabaseUsageDatapoint(database: AppDatabase) {
    if (typeof navigator.storage?.estimate == 'function') {
        const storageEstimate = await navigator.storage.estimate().catch(() => undefined);
        const usage = storageEstimate?.usageDetails?.indexedDB;
        if (usage !== undefined) {
            const settings = await loadOfflineSettingsFromDatabase(database);
            logUsage(
                'OfflineDBUsage',
                {
                    usage,
                    settings: JSON.stringify(settings),
                },
                {
                    mailbox: database.mailboxInfo,
                }
            );
        }
    }
}
