import type { TraceErrorObject } from 'owa-trace';
import type { CustomDataMap } from 'owa-analytics-types';
import { AriaDatapoint } from './AriaDatapoint';
import { getActionMetadata } from '../utils/getActionMetadata';

import { getErrorHash } from 'owa-analytics-utils/lib/addErrorToCustomData';

const diagnosticsColumn = 'diagnostics';

export default class ErrorDatapoint extends AriaDatapoint {
    constructor(message: string, details: TraceErrorObject | undefined, props: CustomDataMap) {
        const hash = getErrorHash(message, details);
        super(<string>hash, undefined, undefined, props);
        try {
            let diagnosticInfo = details?.diagnosticInfo || '';
            const { stack, queue } = getActionMetadata();
            if (stack && stack.length > 0) {
                diagnosticInfo += '|stack:' + stack;
            }
            if ((details?.component || details?.addQueue) && queue && queue.length > 0) {
                diagnosticInfo += '|queue:' + queue;
            }
            this.addDataWithPiiScrubbing(diagnosticsColumn, diagnosticInfo);
            if (self?.navigator && typeof self.navigator.onLine == 'boolean') {
                this.addData('online', self.navigator.onLine);
            }
        } catch (e) {
            this.addDataWithPiiScrubbing(diagnosticsColumn, e.message);
        }
    }
}
