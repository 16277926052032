import type { Table } from 'dexie';
import type { ClientCalendarEvent } from 'owa-graph-schema';
import type { DexieKey } from 'owa-database-schema';
import { compositeDexieKey } from 'owa-database-schema';

type MetaData = {
    isPartialEvent: number; // number is used to create an IndexedDB index based on this value. "0" indicates "false" and "1" - "true"
    /**
     * Timestamp of when was the event last written to IDB with its full properties
     */
    fullEventInsertedAt?: Date;
    /**
     * Identifier of the process that inserted the full event into IDB
     * This could be Notifications, Sync, etc
     */
    fullEventInsertedBy?: string;
    /**
     * Timestamp of when this event was last written to IDB as a partial event
     */
    partialEventInsertedAt?: Date;
    /**
     * Identifier of the process that inserted the partial event into IDB
     * This could be Notifications, Sync, etc
     */
    partialEventInsertedBy?: string;
};

export type CalendarEventTableType = ClientCalendarEvent & {
    MetaData: MetaData;
};

export const EVENTS_TABLE_NAME = 'events';
export type EventsTable = Table<CalendarEventTableType, string>;

const EVENTS_ID_KEY: DexieKey<ClientCalendarEvent> = 'ItemId.Id';
export const EVENTS_PARENT_FOLDER_ID_KEY: DexieKey<ClientCalendarEvent> = 'ParentFolderId.Id';
export const EVENTS_TO_FETCH_KEY: DexieKey<CalendarEventTableType> = 'MetaData.isPartialEvent';
export const EVENTS_INSTANCE_KEY = compositeDexieKey<CalendarEventTableType>([
    'ParentFolderId.Id',
    'InstanceKey',
]);
export const EVENT_START_DATE_KEY = compositeDexieKey<CalendarEventTableType>([
    'ParentFolderId.Id',
    'Start',
]);
export const EVENT_END_DATE_KEY = compositeDexieKey<CalendarEventTableType>([
    'ParentFolderId.Id',
    'End',
]);

export const eventsSchema = {
    events: `${EVENTS_ID_KEY},${EVENTS_PARENT_FOLDER_ID_KEY},${EVENTS_TO_FETCH_KEY},${EVENTS_INSTANCE_KEY},${EVENT_START_DATE_KEY},${EVENT_END_DATE_KEY}`,
};
