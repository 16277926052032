import type { AppDatabase } from 'owa-offline-database';
import { setTransactionSource } from 'owa-offline-database';
import { flattenObject } from '../utils/flattenObject';
import Dexie from 'dexie';

export async function updateSetting(database: AppDatabase, name: string, data: any): Promise<any> {
    return database.transaction('rw', database.settings, () => {
        // Writes from the main thread must be instrumented with
        // setTransactionSource in order to provide DB notifications.
        setTransactionSource(Dexie.currentTransaction, 'localLie');
        return database.settings.update(name, flattenObject({ data }));
    });
}
