import { ApolloLink, Observable } from '@apollo/client';
import type { Operation } from '@apollo/client';
import type { QueuedActionContext } from '../types/QueuedActionContext';
import type { RemotePendingResult } from '../types/RemotePendingResult';
import { getOperationAST } from 'graphql';
import { trace, errorThatWillCauseAlertAndThrow } from 'owa-trace';
import { enqueue } from '../queue/actionQueue';
import { isQueueDbUnhealthy } from '../util/trackQueueHealth';
import { isOfflineSyncEnabled } from 'owa-offline-sync-feature-flags';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getIsDataWorkerLeaderFaulted } from 'owa-config';
import { getMailboxInfoFromOperation } from 'owa-request-options';
import { emitSyncEvent } from 'owa-offline-sync-diagnostics';
import type { ResolverContext } from 'owa-graph-schema';

// returned when the online part of a the resolution (local or remote)
// has been queued for online replay but not completed.
const remotePendingResult: RemotePendingResult = {
    extensions: { remotePending: true },
};

// dummy remote link for pending queued actions.  it hardcodes a response indicating the
// is in a pending/queued state.  typically, this isn't going
// to be observed by any code because in most cases the caller request is completed by an offline resolver
// committing local lies and returning a local result, but if there's isn't such a resolver, the caller
// will get this response as a fallback.
//
// if we're executing an offline action there's no enabled 'web' resolver, then the 'remote link'
// will be called directly by the localRemoteRouter just as if we were trying to execute against the gateway
//
// if we're executing an offline only action there's is enabled 'web' resolver, the offlineResolverSync code
// that merges the idb/web resolvers will see it's an offline only action and 'fallback' to the remote link
export function remotePendingLink() {
    const link = new ApolloLink(() => {
        return new Observable(observer => {
            observer?.next(remotePendingResult);
            observer?.complete();
        });
    });

    return link;
}

export function queuedActionLink() {
    const link = new ApolloLink((operation, forward) => {
        const opNode = getOperationAST(operation.query);
        const ctx = operation.getContext() as Partial<QueuedActionContext>;

        if (ctx.queuedAction) {
            // only expected queue directives on mutations
            if (opNode?.operation !== 'mutation') {
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * The error name (message) must be a string literal (no variables in it).
                 *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                errorThatWillCauseAlertAndThrow(
                    `operation ${operation.operationName} attempted to queue non-mutation ${opNode?.operation}`
                );
            }

            const mailboxInfo = getMailboxInfoFromOperation(operation);
            const isLeaderFaulted = getIsDataWorkerLeaderFaulted();
            const isQueueFaulted = isQueueDbUnhealthy();
            const isSyncEnabled = isOfflineSyncEnabled(mailboxInfo);
            const flightName = ctx.queuedAction.flight;
            const isFlightEnabled = flightName ? isFeatureEnabled(flightName) : true;

            if (!isSyncEnabled || !isFlightEnabled || isLeaderFaulted || isQueueFaulted) {
                // feature submitted a queued action, but sync isn't enabled.  ignore the queue directives.
                ctx.queuedAction = undefined;
                operation.setContext(ctx);
                traceQueueEnabledStatus(
                    operation?.operationName,
                    flightName || 'undefined flight',
                    isSyncEnabled,
                    isFlightEnabled,
                    isLeaderFaulted,
                    isQueueFaulted
                );
            }
        }

        const localSub = forward(operation);

        if (!isQueuable(operation)) {
            // if it's a non queued operation, just return the observable directly
            return localSub;
        } else {
            // if it's a queued operation, grab the local result, stuff it on the context, queue for online execution, and complete the outer sub
            const queuedActionContext: QueuedActionContext = ctx as QueuedActionContext;
            return new Observable(outer => {
                const sub = localSub.subscribe({
                    next: localResult => {
                        localResult = stampRemotePending(localResult);
                        queuedActionContext.queuedAction.localResult = localResult;
                        operation.setContext(queuedActionContext);
                        outer?.next(localResult);
                    },
                    error: localErr => {
                        localErr = stampRemotePending(localErr);
                        queuedActionContext.queuedAction.localResult = localErr;
                        operation.setContext(queuedActionContext);
                        enqueue(operation, forward);
                        outer?.error(localErr);
                    },
                    complete: () => {
                        enqueue(operation, forward);
                        outer?.complete();
                    },
                });

                return () => {
                    sub.unsubscribe();
                };
            });
        }
    });

    return link;
}

function traceQueueEnabledStatus(
    opName: string,
    flightName: string,
    isSyncEnabled: boolean,
    isFlightEnabled: boolean,
    isLeaderFaulted: boolean,
    isQueueFaulted: boolean
) {
    var message = 'Queue directives ignored: ';
    if (!isSyncEnabled) {
        message += 'Sync is not enabled. ';
    }

    if (!isFlightEnabled) {
        message += `Flight ${flightName} is not enabled. `;
    }

    if (isLeaderFaulted) {
        message += 'Worker leader is faulted. ';
    }

    if (isQueueFaulted) {
        message += 'Queue is faulted. ';
    }

    trace.warn(message, 'actionQueue');
    emitSyncEvent(message, { flightName, opName });
}

export function isQueuable(op: Operation): boolean {
    const ctx = op.getContext() as ResolverContext;
    const qaCtx = ctx as any as QueuedActionContext;
    return qaCtx?.queuedAction?.state == 'OfflineExecution' && ctx.resolverPolicy !== 'localOnly';
}

export function stampRemotePending(result: any): RemotePendingResult {
    if (result?.extensions) {
        result.extensions.remotePending = true;
    } else if (result) {
        result.extensions = { remotePending: true };
    } else {
        result = remotePendingResult;
    }

    if (result.errors) {
        result.errors.forEach(stampRemotePending);
    }

    return result;
}
