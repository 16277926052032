import type NotificationSubscription from './NotificationSubscription';
import type NotificationCallback from './NotificationCallback';
import type { ConnectionType } from '../HubConnection/utils/ConnectionType';
export type SubscriptionStatus = any;

export interface SubscriptionReference {
    // The actual subscription object
    subscription: NotificationSubscription;

    // We store the callback to find the reference to the subscription later
    callback: NotificationCallback;
}

export interface SubscriptionState {
    // Callback/Subscription pairs for this subscription ID
    refs: SubscriptionReference[];

    // Whether subscription is active on the server
    status: SubscriptionStatus;

    // Number of retries since last successful subscribe
    retries: number;

    // setTimeout handle for the current retry, used to cancel
    // setTimeout will always return a non-zero value
    pendingRetryHandle: number;

    // source of notification
    source: ConnectionType;
}

export interface AccountSubscriptionKey {
    sourceId: string;
    subscriptionId: string;
}

export interface SubscriptionTrackerState {
    [subscriptionId: string]: SubscriptionState;
}

export function initialSubscriptionTrackerState(): SubscriptionTrackerState {
    return {};
}
