import type SingleResponseMessage from 'owa-service/lib/contract/SingleResponseMessage';
import type { PerformanceDatapointType } from 'owa-analytics-types';
import createServiceFetchError from './createServiceFetchError';
import checkIfResponseSuccess from './checkIfResponseSuccess';

export default function handleServerResponseSuccessAndError(
    response: SingleResponseMessage | SingleResponseMessage[] | undefined | null,
    dp?: PerformanceDatapointType | undefined
): Promise<void> {
    if (!response) {
        if (dp) {
            dp.addCustomData({ HSR_NO_R: dp.calculateTotalDuration() });
        }
        return Promise.reject(
            createServiceFetchError('500', 'Server returned a null response!', undefined)
        );
    }

    const responseItems = Array.isArray(response) ? response : [response];
    for (const responseItem of responseItems) {
        if (responseItem && !checkIfResponseSuccess(responseItem)) {
            if (dp) {
                dp.addCustomData({ HSR_R_E: dp.calculateTotalDuration() });
            }
            return Promise.reject(
                createServiceFetchError(
                    responseItem.ResponseCode,
                    responseItem.StackTrace,
                    responseItem.MessageText
                )
            );
        }
    }
    if (dp) {
        dp.addCustomData({ HSR_S: dp.calculateTotalDuration() });
    }
    return Promise.resolve();
}
