import { setItem } from 'owa-local-storage';

import { OFFLINE_ENABLED_KEY } from 'owa-offline-sync-feature-flags';
import { setOfflineDisabledReason } from './setOfflineDisabledReason';
import type { OfflineDisabledReason } from './OfflineDisabledReason';

export function toggleOffline(value: string, reason: OfflineDisabledReason) {
    setItem(self, OFFLINE_ENABLED_KEY, value);
    setOfflineDisabledReason(reason);
}
