import { VerbosePerfEventType, isEventQueryStringEnabled } from '../datapoints/AriaCoreDatapoint';
import type { AriaDatapointType, CoreAnalyticsOptions } from 'owa-analytics-types';
import { type DatapointVariant } from 'owa-analytics-types';
import getFlightControl from './getFlightControl';
import { isCurrentSessionInSample } from 'owa-analytics-shared/lib/settings/getSessionSampleBucket';
import { isEventDisabled } from '../analyticsApprovals';
import { getAnalyticsFlightsAndAppSettings } from '../settings/getAnalyticsFlightsAndAppSettings';
import { getSessionInfo } from '../settings/getSessionInfo';

let errorsInSession = 0;

export default async function shouldLogDatapoint(
    datapoint: AriaDatapointType,
    analyticsOptions: CoreAnalyticsOptions,
    eventType: string
): Promise<boolean> {
    // if we enable with a query string parameter, we should always allow this datapoint to get logged
    if (isEventQueryStringEnabled(eventType)) {
        return true;
    }

    // Get disabled events from ECS (analyticsOptions) or in code (isEventDisabled).
    // OOBE and other scenarios might not get events from ECS, so we need to check both.
    const disabledDatapoints = getAnalyticsFlightsAndAppSettings()?.disabledDatapoints || [];
    if (
        isEventDisabled(datapoint.getEventName()) ||
        disabledDatapoints.includes(datapoint.getEventName())
    ) {
        return false;
    }

    // don't log client_verbose datapoints if there is no whitelist or the datapoint is not on the whitelist
    if (
        datapoint.getEventName() &&
        eventType == VerbosePerfEventType &&
        (!analyticsOptions.verboseWhiteListEvents ||
            analyticsOptions.verboseWhiteListEvents.indexOf(datapoint.getEventName()) == -1)
    ) {
        return false;
    }

    if (eventType == 'client_error') {
        errorsInSession++;
        if (
            analyticsOptions.maxErrorsPerSession &&
            errorsInSession > analyticsOptions.maxErrorsPerSession
        ) {
            return false;
        }
    }

    const controlFlight = getFlightControl(datapoint, analyticsOptions, eventType)?.flight;
    if (
        controlFlight &&
        !(analyticsOptions.defaultFeatureFlags || []).some(f => f.toLowerCase() == controlFlight)
    ) {
        return false;
    }

    const options = datapoint.getOptions();
    // if logEvery is set to 5, then we should log every 5th of these datapoints
    if (
        options?.logEvery &&
        datapoint.sessionOccurrence &&
        (datapoint.sessionOccurrence - 1) % options?.logEvery != 0
    ) {
        return false;
    }

    const sessionInfo = await getSessionInfo(analyticsOptions.startupInfo);
    if (options?.ring && options.ring != sessionInfo?.logicalRing) {
        return false;
    }

    return getIsSessionSampled(datapoint) && getDoesVariantApply(datapoint);
}

function getIsSessionSampled(datapoint: AriaDatapointType) {
    const sessionSampleRate = datapoint.getOptions()?.sessionSampleRate;
    return (
        !datapoint.getOptions() || !sessionSampleRate || isCurrentSessionInSample(sessionSampleRate)
    );
}

function getDoesVariantApply(datapoint: AriaDatapointType) {
    switch (datapoint.getOptions()?.variant) {
        case 0:
            return false;
        case 2:
            return datapoint.sessionOccurrence == 1;
        case 3:
            return !!datapoint.sessionOccurrence && datapoint.sessionOccurrence > 1;
        default:
            return true;
    }
}
