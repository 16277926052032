import { logUsage, PerformanceDatapoint } from 'owa-analytics';
import type { SyncQueueSummary } from 'owa-offline-sync-diagnostics';
import { onSyncQueueChanged, onSyncModuleCompleted } from 'owa-offline-sync-diagnostics';
import type { MailboxInfo } from 'owa-client-types';

import type { SyncModule } from './SyncModule';
import { SyncDatapointWaterfallMarkers } from './SyncModule';
import { handleSyncError } from './utils/handleSyncError';
import { scrubForPii } from 'owa-config';

export type SyncModuleCompletionListener = (type: string) => void;
const ABORT_CURRENT_MODULE_TIMER = 5 * 60 * 1000;

export class SyncQueue {
    private _syncQueue: SyncModule<any, any>[] = [];
    private _currentModule: SyncModule<any, any> | undefined;
    private _isRunning = true;
    private _completionListeners: SyncModuleCompletionListener[] = [];
    private _mailboxInfo: MailboxInfo;

    constructor(mailboxInfo: MailboxInfo) {
        this._mailboxInfo = mailboxInfo;
    }

    private clearQueuedSyncModules() {
        this._syncQueue.splice(0);
        this.onSyncQueueUpdated();
    }

    private async run() {
        let previousModule;
        while (this._syncQueue.length > 0 && this.isRunning && !previousModule?.getIsAborted()) {
            this._currentModule = this._syncQueue.shift();
            this.onSyncQueueUpdated();
            previousModule = this._currentModule;
            await this.runSyncModule(this._currentModule);
        }
        this._currentModule = undefined;
        this.onSyncQueueUpdated();
    }

    private isModuleStuck(syncModule: SyncModule<any, any>) {
        return syncModule.runStatus
            ? Date.now() - syncModule.runStatus.time >= ABORT_CURRENT_MODULE_TIMER
            : false;
    }

    private isSafeToAbort(syncModule: SyncModule<any, any>) {
        return [
            'ConversationNodes',
            'InlineImages',
            'UnstackedMessageBodies',
            'MessageBodies',
        ].includes(syncModule.type);
    }

    private getQueueSummary() {
        const summary: SyncQueueSummary = {
            id: this._mailboxInfo.sourceId,
            queue: [],
        };

        if (this._currentModule) {
            summary.queue.push({
                type: this._currentModule.type,
                description: this._currentModule.getDescription(),
            });
        }
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        this._syncQueue.forEach(item =>
            summary.queue.push({
                type: item.type,
                description: item.getDescription(),
            })
        );
        return summary;
    }

    private onSyncQueueUpdated() {
        onSyncQueueChanged(this.getQueueSummary.bind(this), this._mailboxInfo);
    }

    private async runSyncModule(module?: SyncModule<any, any>): Promise<void> {
        if (!module || !this.isRunning) {
            return;
        }

        const type = module.type;
        let didRunSuccessfully = false;
        let error;
        try {
            const start = Date.now();
            await module.initialize();

            if (!this.isRunning || module.getIsAborted()) {
                return;
            }

            if (!module.isSyncComplete()) {
                if (module.shouldLogPerformanceDatapoint()) {
                    const dp = new PerformanceDatapoint('OfflineSync', {
                        customStartTime: start,
                        mailbox: module.mailboxInfo,
                        timeout: module.datapointTimeout,
                    });
                    dp.addCustomData({ type: module.type });
                    dp.addToCustomWaterfall(
                        SyncDatapointWaterfallMarkers.Initialized_E,
                        'Initialized_E',
                        false
                    );
                    module.perfDatapoint = dp;
                }
                do {
                    const isAborted = await module.run(this);

                    if (!this.isRunning || isAborted) {
                        return;
                    }
                } while (!module.isSyncComplete());
                didRunSuccessfully = true;
            }
        } catch (err) {
            error = err;
            module.emitSyncEvent(`SyncQueue: error running ${type}`, { message: err.message });
            handleSyncError(err, module);
            module.perfDatapoint?.addCustomData({
                errorMessage: scrubForPii(err.message),
                errorStack: scrubForPii(err.stack),
            });
        }
        await module.onCompleted(didRunSuccessfully);
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        this._completionListeners.forEach(listener => listener(type));

        onSyncModuleCompleted(
            {
                type: module.type,
                description: module.getDescription(),
                queueTime: module.creationTime,
                startTime: module.firstRunTime,
                durationMs: module.firstRunTime
                    ? Date.now() - (module.firstRunTime || 0)
                    : undefined,
                error: error?.message,
            },
            this._mailboxInfo
        );
    }

    public get isRunning() {
        return this._isRunning;
    }

    public get isEmpty() {
        return this._syncQueue.length === 0;
    }

    public queue(syncModule: SyncModule<any, any>, prioritize?: boolean) {
        if (!this.isRunning) {
            syncModule.emitSyncEvent('failed to queue sync module', {});
            return;
        }

        // Check for duplicates. We don't check the currently running module since it might be almost done.
        for (const module of this._syncQueue) {
            if (module.type === syncModule.type && module.isDuplicateModule(syncModule)) {
                if (prioritize) {
                    // If we are prioritizing, remove the existing module
                    // and it will be put back to the front of the list below.
                    const index = this._syncQueue.indexOf(module);
                    this._syncQueue.splice(index, 1);
                    break;
                }
                return;
            }
        }

        if (prioritize) {
            if (this._currentModule && this.isSafeToAbort(this._currentModule)) {
                // If its safe to abort the current module, do so.
                this._currentModule.abort();
                this._currentModule.emitSyncEvent('aborting due to prioritized module', {
                    abortedModule: this._currentModule.type,
                    prioritizedModule: syncModule.type,
                });
                this._currentModule = undefined;
            }
            // If we are prioritizing, add the module to the front of the list.
            this._syncQueue.unshift(syncModule);
        } else {
            // For now just add it to the end of the list.
            // We will probably eventually want to add support for de-duping and prioritization.
            this._syncQueue.push(syncModule);
        }

        syncModule.emitSyncEvent('queued sync module', {
            length: this._syncQueue.length,
            idle: !this._currentModule,
        });

        if (!this._currentModule) {
            // Start syncing now that we have something to work on.
            this.run();
        } else if (this.isModuleStuck(this._currentModule)) {
            this._currentModule.abort();
            this._currentModule.emitSyncEvent('aborting due to stuck module', {
                code: this._currentModule?.runStatus?.code,
                time: this._currentModule?.runStatus?.time,
            });
            logUsage(
                'sync_abort',
                {
                    type: this._currentModule.type,
                    runStatusCode: this._currentModule?.runStatus?.code,
                    runStatusTime: this._currentModule?.runStatus?.time,
                },
                {
                    mailbox: this._mailboxInfo,
                }
            );
            this.run();
        }

        this.onSyncQueueUpdated();
    }

    public start() {
        this._isRunning = true;
    }

    public stop() {
        this._isRunning = false;
        this.clearQueuedSyncModules();
    }

    public registerSyncCompletionListener(listener: SyncModuleCompletionListener) {
        this._completionListeners.push(listener);
    }

    public unregisterSyncCompletionListener(listener: SyncModuleCompletionListener) {
        const index = this._completionListeners.findIndex(item => item === listener);
        if (index !== -1) {
            this._completionListeners.splice(index, 1);
        }
    }

    public getCurrentModuleForTests() {
        return this._currentModule;
    }

    public getSyncQueueForTests() {
        return this._syncQueue;
    }
}
