import EventEmitter from 'owa-event-emitter';
import NotificationEventType from './schema/NotificationEventType';

let emitter: EventEmitter;

export default function getNotificationEmitter(): EventEmitter {
    return (emitter ??= new EventEmitter());
}

export function setNotificationEmitter(value: EventEmitter) {
    emitter = value;
}

export function emitChannelDataEvent(s: string): void {
    getNotificationEmitter().emit(NotificationEventType.ChannelData, s);
}
