import type { TraceErrorObject } from 'owa-trace';

import {
    addErrorToCustomData,
    addFinalErrorFieldsToCustomData,
    ignoreError,
} from 'owa-analytics-utils/lib/addErrorToCustomData';

import ErrorDatapoint from '../../datapoints/ErrorDatapoint';
import UsageDatapoint from '../../datapoints/UsageDatapoint';
import { logDatapoint } from '../logDatapoint';

const GREY_ERROR_TABLE = 'client_grey_error';

interface ErrorDatapointOptions {
    logInDevMode: boolean;
    errorVersion: number;
    isCreateRootOn: boolean;
}

export function logErrorDatapoint(
    message: string,
    errorDetails: TraceErrorObject | undefined,
    options?: ErrorDatapointOptions
) {
    try {
        if (ignoreError(message)) {
            return;
        }

        const { datapointName, customData } = addErrorToCustomData(message, errorDetails);

        // This is to allow for filtering dev-mode datapoints in a query where we don't have a reliable build number
        if (options) {
            customData.errorVersion = options.errorVersion;
        }

        if (datapointName) {
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the constructor. */
            logDatapoint(new UsageDatapoint(datapointName, customData), GREY_ERROR_TABLE);
        } else {
            const { customData: customDataWithFinalFields, table } =
                addFinalErrorFieldsToCustomData(
                    message,
                    errorDetails,
                    customData,
                    options?.logInDevMode
                );

            logDatapoint(
                new ErrorDatapoint(message, errorDetails, customDataWithFinalFields),
                table
            );
        }
    } catch {
        // TODO OW 48077
    }
}
