import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { getCoprincipalAccountPersistenceId } from 'owa-account-source-list/lib/utils/pluggableHost';
import type { MailboxInfo } from 'owa-client-types';
import { getItem, removeItem, setItem } from 'owa-local-storage';
import { OFFLINE_ENABLED_KEY } from './constants';

export function migrateLocalStorageKey() {
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();
    const legacyStorageKey = getLegacyLocalStorageKey(mailboxInfo);
    const isOfflineEnabled = getItem(self, legacyStorageKey);

    if (isOfflineEnabled != undefined) {
        setItem(self, OFFLINE_ENABLED_KEY, isOfflineEnabled);
        removeItem(self, legacyStorageKey);
    }
}

function getLegacyLocalStorageKey(mailboxInfo: MailboxInfo) {
    return `${OFFLINE_ENABLED_KEY}-${getCoprincipalAccountPersistenceId(mailboxInfo)}`;
}
