import type { HierarchyNotificationPayload } from 'owa-graph-schema';
import { type ChangeType, getDatabase } from 'owa-offline-database';
import { lazyGetNotificationEmitter, type SubscriptionStatus } from 'owa-notification-manager';
import type { MailboxInfo } from 'owa-client-types';

// Subscribe to hear hiearchy notifications due to changes in the local store.
// Return value is unsubscribe callback
export async function subscribeToHierarchyNotifications(
    mailboxInfo: MailboxInfo,
    subscriptionId: string,
    callback: (notification: HierarchyNotificationPayload) => void
): Promise<() => void> {
    const database = getDatabase(mailboxInfo);
    const sanitizedMailboxInfo = JSON.parse(JSON.stringify(mailboxInfo));

    const unsubscribe = database.subscribeToIdbChanges(database.folders, change => {
        let notification: HierarchyNotificationPayload | undefined;
        switch (change.type) {
            case 1:
                const folder = change.obj;
                notification = {
                    __typename: 'HierarchyNotificationPayload',
                    id: subscriptionId,
                    mailboxInfo: sanitizedMailboxInfo,
                    EventType: 'RowModified',
                    FolderType: 'Folder',
                    displayName: folder.displayName,
                    folderId: folder.id,
                    parentFolderId: folder.parentFolderId,
                    itemCount: folder.totalMessageCount,
                    unreadCount: folder.UnreadCount,
                    sortParentId: folder.sortParentId,
                    sortPosition: folder.sortPosition,
                    source: 'idb',
                    workerTime: change.workerTime,
                } as HierarchyNotificationPayload;
        }

        if (notification) {
            callback(notification);
            lazyGetNotificationEmitter
                .import()
                .then(get => get().emit('NotificationData', notification));
        }
    });

    const emitter = (await lazyGetNotificationEmitter.import())();
    const subscription = {
        subscriptionId,
        subscriptionParameters: {
            NotificationType: 'HierarchyNotification',
        },
    };
    emitter.emit('SubscriptionAdded', subscription);
    emitter.emit('SubscriptionUpdated', subscription, {
        status: 3,
        refs: [],
    });

    return () => {
        unsubscribe();
        emitter.emit('SubscriptionRemoved', subscription);
    };
}
