import type { HierarchyNotificationPayload } from 'owa-graph-schema';
import { getDatabase } from 'owa-offline-database';
import type { AppDatabase } from 'owa-offline-database';
import debounce from 'lodash-es/debounce';
import type { DebouncedFunc } from 'lodash-es/debounce';
import type { FolderTableType } from 'owa-offline-folders-schema';
import { getNewSortId } from '../util/getNewSortId';
import { getHashedLogString } from 'owa-logging-utils';
import { emitSyncEvent } from 'owa-offline-sync-diagnostics';

// We debounce saving hierarchy notifications to avoid consuming too much idb throughput
// during notification storms - e.g. when the service is running a bulk operation.
// These typically just update unread counts so it's not too scary to increase the time window
// in which a hierarchy query from the UI might return slightly stale data.
const NOTIFICATION_DEBOUNCE_MS = 1000;

type SaveFunc = DebouncedFunc<() => Promise<unknown>>;
type PendingNotifications = {
    func: SaveFunc;
    notifications: HierarchyNotificationPayload[];
};
const pendingNotificationsMap: Map<object, PendingNotifications> = new Map();

/* eslint-disable-next-line owa-custom-rules/require-MailboxInfoInNotificationCallback  -- (https://aka.ms/OWALintWiki)
 * Notification callbacks must include a MailboxInfo parameter, see https://aka.ms/multiaccountlinter
 *	> Notification Payloads must have an associated MailboxInfo to specify the account. This function does not have a MailboxInfo following the notification payload. */
export async function saveHierarchyNotification(notification: HierarchyNotificationPayload) {
    if (!notification.mailboxInfo) {
        throw new Error('saveHierarchyNotification: missing mailboxInfo in notification');
    }

    // We only want to save type 'Folder' aka mail folders
    // Folder create notifications are FolderType 'Unknown' and EventType 'RowAdded', those need to be handled as well
    const shouldHandle =
        notification.FolderType === 'Folder' ||
        (notification.FolderType == 'Unknown' && notification.EventType == 'RowAdded');
    if (!shouldHandle) {
        return;
    }

    const database = getDatabase(notification.mailboxInfo);
    let pendingNotifications = pendingNotificationsMap.get(database);
    if (!pendingNotifications) {
        const notifications: HierarchyNotificationPayload[] = [];
        pendingNotifications = {
            func: debounce(
                () => savePendingHierarchyNotifications(database),
                NOTIFICATION_DEBOUNCE_MS,
                {
                    maxWait: NOTIFICATION_DEBOUNCE_MS,
                }
            ),
            notifications,
        };
        pendingNotificationsMap.set(database, pendingNotifications);
    }

    pendingNotifications.notifications.push(notification);
    return pendingNotifications.func();
}

export async function savePendingHierarchyNotifications(database: AppDatabase) {
    const pendingNotifications = pendingNotificationsMap.get(database);
    if (!pendingNotifications) {
        return;
    }

    // Protect against missing folder ids up front to avoid extra checks below.
    const notifications = pendingNotifications.notifications.filter(
        notification => notification.folderId && notification.parentFolderId
    );

    pendingNotificationsMap.set(database, {
        ...pendingNotifications,
        notifications: [],
    });

    return database.transaction('rw', database.folders, async () => {
        const oldFolderMap: Map<string, FolderTableType | undefined> = new Map();

        // Use a single bulkGet to read the existing state of all these folders and their parents.
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        notifications.forEach(notification => {
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            oldFolderMap.set(notification.folderId!, undefined);
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            oldFolderMap.set(notification.parentFolderId!, undefined);
        });
        const folders = await database.folders.bulkGet(Array.from(oldFolderMap.keys()));
        folders.forEach(folder => {
            if (folder) {
                oldFolderMap.set(folder.id, folder);
            }
        });

        const newFolderMap: Map<string, FolderTableType> = new Map();

        for (const notification of notifications) {
            // It would be natural to handle RowDeleted as well here
            // but in practice the service doesn't notify us about
            // folder deletion.
            switch (notification.EventType) {
                case 'RowModified':
                case 'RowAdded':
                    const folder =
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                         *	> Forbidden non-null assertion. */
                        newFolderMap.get(notification.folderId!) ||
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                         *	> Forbidden non-null assertion. */
                        oldFolderMap.get(notification.folderId!);
                    const parentFolder =
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                         *	> Forbidden non-null assertion. */
                        newFolderMap.get(notification.parentFolderId!) ||
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                         *	> Forbidden non-null assertion. */
                        oldFolderMap.get(notification.parentFolderId!);

                    // If it's a modification and the row isn't in the database, don't add it
                    if (parentFolder && (folder || notification.EventType === 'RowAdded')) {
                        const oldFolder = notification.folderId
                            ? oldFolderMap.get(notification.folderId)
                            : undefined;
                        const oldDisplayName = oldFolder?.displayName;
                        const newSortIndex = await getNewSortId(
                            database,
                            oldDisplayName,
                            notification.displayName,
                            parentFolder,
                            oldFolder?.MetaData?.sortIndex
                        );
                        const update: FolderTableType = {
                            __typename: 'MailFolder',
                            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                             *	> Forbidden non-null assertion. */
                            id: notification.folderId!,
                            displayName: notification.displayName || '',
                            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                             *	> Forbidden non-null assertion. */
                            parentFolderId: notification.parentFolderId!,
                            totalMessageCount: notification.itemCount || 0,
                            UnreadCount: notification.unreadCount || 0,
                            type: notification.FolderType || folder?.type,
                            childFolderCount: 0, // TODO: how to deal with this in sync
                            mailboxInfo: notification.mailboxInfo,
                            MetaData: { sortIndex: newSortIndex },
                        };

                        const folderToWrite = folder
                            ? {
                                  ...folder,
                                  ...update,
                              }
                            : update;

                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                         *	> Forbidden non-null assertion. */
                        newFolderMap.set(notification.folderId!, folderToWrite);

                        emitSyncEvent(
                            'SyncManager: saving and syncing hierarchy notification',
                            {
                                eventType: notification.EventType,
                                folderId: getHashedLogString(notification.folderId),
                                unreadCount: notification.unreadCount,
                                sortIndex: newSortIndex,
                            },
                            notification.mailboxInfo
                        );
                    }
            }
        }

        return database.folders.bulkPut(Array.from(newFolderMap.values()));
    });
}
