import type { Table } from 'dexie';
import {
    type PerformanceDatapoint,
    logUsage as logUsageAnalytics,
    defaultDatapointTimeout,
} from 'owa-analytics';
import type { CustomData, DatapointOptions } from 'owa-analytics-types';
import { DatapointStatus } from 'owa-analytics-types';
import type { MailboxInfo } from 'owa-client-types';
import { scrubForPii } from 'owa-config';
import type { LogData } from 'owa-logging-utils';
import type { AppDatabase } from 'owa-offline-database';
import { getDatabase } from 'owa-offline-database';
import { emitSyncEvent } from 'owa-offline-sync-diagnostics';
import { loadSyncState, saveSyncState } from 'owa-offline-sync-state';
import type { SyncModuleState } from 'owa-offline-sync-state-schema';
import createServiceFetchError from 'owa-service-utils/lib/createServiceFetchError';
import handleServerResponseSuccessAndError from 'owa-service-utils/lib/handleServerResponseSuccessAndError';
import type SingleResponseMessage from 'owa-service/lib/contract/SingleResponseMessage';
import { SyncModuleRunStatus } from './SyncModuleRunStatus';
import type { SyncQueue } from './SyncQueue';
import { saveChangesAndSyncState } from './store/saveChangesAndSyncState';

/**
 * A SyncModule represents an individual component that is responsible for syncing specific data to the local database.
 *
 * We pull out the common pattern of iterative sync here and individual modules plug in the unique details
 * like how to make the service request and how to persist the downloaded data.
 */
export abstract class SyncModule<TSyncState, TSyncResponse> {
    public abstract type: string;
    public abstract syncStateName: string;
    public mailboxInfo: MailboxInfo;
    public creationTime: number;
    public firstRunTime: number | undefined;
    public runStatus: SyncModuleRunStatus | undefined;
    public isCleanupModule = false;
    public database: AppDatabase;

    public datapointTimeout: number = defaultDatapointTimeout;

    /**
     * Datapoint that is defined and captures general telemetry of the SyncModule
     * Should any implementation desire to capture data, it must override {@link shouldLogPerformanceDatapoint} to return true
     * in the conditions it wished to capture data on.
     *
     * @see {@link shouldLogPerformanceDatapoint}
     */
    public perfDatapoint: PerformanceDatapoint | undefined;

    protected isAborted: boolean = false;
    protected syncState!: TSyncState;
    protected lastSuccessfulSyncTime: number = 0;
    protected lastSyncResponse: TSyncResponse | undefined;

    protected timeWaitingServer: number = 0;
    protected timeWritingDatabase: number = 0;
    protected timesRun: number = 0;
    protected runReason: string;

    constructor(mailboxInfo: MailboxInfo, runReason = 'Unknown') {
        this.mailboxInfo = JSON.parse(JSON.stringify(mailboxInfo));
        this.creationTime = new Date().getTime();
        this.database = getDatabase(mailboxInfo);
        this.runReason = runReason;
    }

    protected abstract getEmptySyncState: () => TSyncState;
    protected abstract syncChangesFromServer: (syncState: TSyncState) => Promise<TSyncResponse>;
    protected abstract getSyncStateFromResponse: (
        previousSyncState: TSyncState,
        response: TSyncResponse
    ) => TSyncState;
    // Called after successful sync to save the changes to the store.
    // All operations are wrapped in a transaction, no additional transactions
    protected abstract saveChangesToStore: (response: TSyncResponse) => Promise<unknown>;
    protected abstract getTables: () => Array<Table>;
    public abstract isSyncComplete: () => boolean;

    /**
     * Used to avoid queueing extra duplicate modules.
     *
     * Override in cases like folder specific sync where module instances of the same type might sync differently
     * @param _module Other instance to evaluate duplication of Sync task from
     * @returns true if tasks are duplicates and only one is necessary, false if both should be ran.
     */
    public isDuplicateModule(_module: SyncModule<TSyncState, TSyncResponse>): boolean {
        return true;
    }

    // Set this to save changes to other databases during each iteration. The sync module is responsible for creating its own transaction if necessary.
    protected saveChangesToOtherDatabases:
        | ((response: TSyncResponse) => Promise<unknown>)
        | undefined;

    protected onSaveChangesComplete: () => void = () => {};

    /**
     *  Load the sync state before trying to run the sync module.
     *
     *  Override to add module specific initialization logic.
     */
    public async initialize() {
        this.firstRunTime = undefined;
        const syncModuleState = await loadSyncState(this.database, this.syncStateName);
        let syncState = syncModuleState?.syncState as TSyncState | undefined;

        if (!syncState) {
            syncState = this.getEmptySyncState();
        }
        this.syncState = syncState;
        this.lastSuccessfulSyncTime = syncModuleState?.lastSuccessfulSyncTime || 0;
    }

    /**
     * Override this method to make the SyncModule log a PerformanceDatapoint called `OfflineSync`.
     * A flag called `type` will have the SyncModule name on CustomData.
     * @returns True if the module should log performance datapoint, false otherwise
     */
    public shouldLogPerformanceDatapoint() {
        return false;
    }

    /**
     * Override to provide details for the diagnostic pane
     * @returns String that will be shown on the diagnostic pane
     */
    public getDescription: () => string = () => '';

    /**
     * The sync engine calls this repeatedly until the module reports isSyncComplete.
     * @param queue {@link SyncQueue} where this module is running from
     * @returns Abort status of the module in case execution should stop or iteration finishes
     */
    public run = async (queue: SyncQueue) => {
        this.timesRun++;
        this.runStatus = new SyncModuleRunStatus();
        if (!this.firstRunTime) {
            this.perfDatapoint?.addToCustomWaterfall(
                SyncDatapointWaterfallMarkers.Runtime_S,
                'Runtime_S',
                false
            );
        }
        this.firstRunTime = this.firstRunTime || new Date().getTime();

        if (!queue.isRunning || this.isAborted) {
            return this.isAborted;
        }

        const getFromServerStart = new Date().getTime();
        this.lastSyncResponse = await this.syncChangesFromServer(this.syncState);
        const getFromServerEnd = new Date().getTime();
        this.timeWaitingServer += getFromServerEnd - getFromServerStart;
        this.runStatus.progress();

        this.syncState = this.getSyncStateFromResponse(this.syncState, this.lastSyncResponse);

        if (!queue.isRunning || this.isAborted) {
            return this.isAborted;
        }

        const start = Date.now();
        if (this.saveChangesToOtherDatabases) {
            await this.saveChangesToOtherDatabases(this.lastSyncResponse);
        }
        await saveChangesAndSyncState(
            this.database,
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            () => this.saveChangesToStore(this.lastSyncResponse!),
            this.getCurrentSyncModuleState(),
            this.getTables(),
            this.isCleanupModule ? 'cleanup' : 'sync'
        );
        const writeEnd = Date.now();
        this.timeWritingDatabase += writeEnd - start;
        this.runStatus.progress();
        this.emitSyncEvent('saved changes', { ms: writeEnd - start });

        if (!queue.isRunning || this.isAborted) {
            return this.isAborted;
        }

        this.onSaveChangesComplete();
        this.runStatus.progress();

        return this.isAborted;
    };

    public onCompleted(didRunSuccessfully: boolean): Promise<unknown> {
        this.perfDatapoint?.addCustomData({ runReason: this.runReason });
        if (this.firstRunTime) {
            this.perfDatapoint?.addToCustomWaterfall(
                SyncDatapointWaterfallMarkers.Runtime_E,
                'Runtime_E',
                false
            );
            const runTime = (new Date().getTime() - this.firstRunTime) / 1000;
            const queued = (this.firstRunTime - this.creationTime) / 1000;
            this.perfDatapoint?.addCustomData({
                timesRun: this.timesRun,
                databaseWriteTimeMs: this.timeWritingDatabase,
                serviceWaitTimeMs: this.timeWaitingServer,
                queued,
                runTime,
            });
            this.emitSyncEvent('completed sync', { queued, runTime });
        }

        if (didRunSuccessfully) {
            this.lastSuccessfulSyncTime = Date.now();
            this.perfDatapoint?.end();
            return saveSyncState(this.database, this.getCurrentSyncModuleState());
        } else {
            this.perfDatapoint?.endWithError(DatapointStatus.RequestNotComplete);
        }

        return Promise.resolve();
    }

    public abort() {
        this.isAborted = true;
        this.perfDatapoint?.addCustomData({
            isAborted: true,
        });
        this.perfDatapoint?.invalidate();
    }

    public getIsAborted() {
        return this.isAborted;
    }

    /**
     * Creates a sync log, with the context prepended.
     *
     * Structure the "message" param roughly like the following template for consistency across sync logs:
     * "[Verb] [Noun] [Additional Info]"
     */
    public emitSyncEvent = (message: string, data: LogData) =>
        emitSyncEvent(`${this.type}: ${message}`, data, this.mailboxInfo);

    public logUsage = (eventName: string, customData?: CustomData, options?: DatapointOptions) =>
        logUsageAnalytics(eventName, customData, { mailbox: this.mailboxInfo, ...options });

    private getCurrentSyncModuleState(): SyncModuleState<TSyncState> {
        return {
            name: this.syncStateName,
            syncState: this.syncState,
            lastSuccessfulSyncTime: this.lastSuccessfulSyncTime,
        };
    }

    protected async handleItemResponseErrors<T extends SingleResponseMessage>(
        messages: (T | undefined)[] | undefined,
        ids: string[]
    ): Promise<T[]> {
        if (!messages) {
            return Promise.reject(
                createServiceFetchError('500', 'Server returned a null response!', undefined)
            );
        }

        const result: T[] = [];
        for (const [index, message] of messages.entries()) {
            if (
                message?.ResponseCode &&
                KnownItemSpecificResponseCodes.includes(message?.ResponseCode)
            ) {
                this.emitSyncEvent('failed item response', { code: message?.ResponseCode });
                const id = index < ids.length ? ids[index] : '';
                this.logUsage('sync_failed_item', {
                    code: message?.ResponseCode,
                    type: this.type,
                    message: scrubForPii(message?.MessageText) || '',
                    stack: scrubForPii(message?.StackTrace) || '',
                    id,
                });
            } else {
                await handleServerResponseSuccessAndError(message);
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
                 * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
                 *	> Forbidden non-null assertion. */
                result.push(message!);
            }
        }

        return result;
    }
}

/**
 * These response codes are item specific like item not found vs. something like server busy which is not item-specific and may be transient
 */
const KnownItemSpecificResponseCodes = [
    'ErrorCorruptData',
    'ErrorItemCorrupt',
    'ErrorItemNotFound',
    'ErrorInvalidIdMalformed',
    'ErrorInternalServerError',
];

/**
 * Custom waterfall markers used for SyncModules datapoint.
 * Implementations that want to capture more markers should extend this enum.
 */
export enum SyncDatapointWaterfallMarkers {
    Initialized_E = 1,
    Runtime_S = 2,
    Runtime_E = 3,
}
