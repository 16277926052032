import type {
    AcceptedQueuedResult,
    QueuedActionResult,
    RejectedQueuedResult,
} from 'owa-data-worker-utils';
import { type QueuedAction } from '../types/QueuedAction';
import { findRejectedResult } from './defaultResultProcessor';
import { getDatabase, setTransactionSource } from 'owa-offline-database';
import type { MailboxInfo } from 'owa-client-types';
import Dexie from 'dexie';

async function updateIDBItemId(
    oldFolderId: string,
    newFolderId: string,
    mailboxInfo: MailboxInfo | undefined
) {
    const database = getDatabase(mailboxInfo);
    await database.transaction('rw', database.folders, async () => {
        // Update Indexed DB with the service id
        setTransactionSource(Dexie.currentTransaction, 'localLie');
        await database.folders.update(oldFolderId, { id: newFolderId });
    });
}

export async function createFolderResultProcessor(
    action: Omit<QueuedAction, 'id'>,
    result: QueuedActionResult
): Promise<AcceptedQueuedResult | RejectedQueuedResult> {
    let rv: AcceptedQueuedResult | RejectedQueuedResult;

    const rejectedResult = await findRejectedResult(result);
    if (rejectedResult) {
        rv = rejectedResult;
    } else {
        // Used by the action queue to remap our temporary local folder id to the server folder id
        const idChanges: Map<string, string> = new Map();

        const localFolderId =
            action.operation.context.queuedAction.localResult?.data?.createFolder?.Id;
        const serverFolderId = result.fetchResult?.data?.createFolder?.Id;

        if (localFolderId && serverFolderId) {
            const mailboxInfo = action.operation.variables?.mailboxInfo;
            updateIDBItemId(localFolderId, serverFolderId, mailboxInfo);
            idChanges.set(localFolderId, serverFolderId);
        }

        rv = {
            fetchResult: result.fetchResult,
            fetchError: result.fetchError,
            idChanges,
        };
    }

    return Promise.resolve(rv);
}
