import { registerAnalyticsAddon } from 'owa-analytics-addons';
import {
    captureAssetsOptics,
    setShouldCaptureAssets,
    captureServiceActionOptics,
} from 'owa-analytics-addon-capture-optics';
import { trace } from 'owa-trace';

/**
 * Register all the analytics addons needed on the main thread
 */
export function registerAnalyticsDataWorkerAddons() {
    trace.info('[Addon] Registering Analytics Data Worker Addons', 'analytics');

    // Register OWS Callbacks
    registerAnalyticsAddon('CaptureAssetsOptics', captureAssetsOptics);
    registerAnalyticsAddon('SetShouldCaptureAssets', setShouldCaptureAssets);
    registerAnalyticsAddon('CaptureServiceActionOptics', captureServiceActionOptics);
}
