import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OfflineSyncManager" */ './lazyIndex')
);

export const lazyInitializeSync = new LazyAction(lazyModule, m => m.initializeSync);
export const lazyHaltSync = new LazyAction(lazyModule, m => m.haltSync);
export const lazyInitializeAccountSync = new LazyAction(lazyModule, m => m.initializeAccountSync);
export const lazyHaltAccountSync = new LazyAction(lazyModule, m => m.haltAccountSync);
export const lazyScheduleCalendarSync = new LazyAction(lazyModule, m => m.scheduleCalendarSync);
export const lazyOnTableReloaded = new LazyAction(lazyModule, m => m.onTableReloaded);
export const lazyOnManualSyncMail = new LazyAction(lazyModule, m => m.onManualSyncMail);
export const lazyOnUserConfigurationUpdated = new LazyAction(
    lazyModule,
    m => m.onUserConfigurationUpdated
);
export const lazyOnManualSyncFolderHierarchy = new LazyAction(
    lazyModule,
    m => m.onManualSyncFolderHierarchy
);
export const lazyOnManualSyncPGAL = new LazyAction(lazyModule, m => m.onManualSyncPGAL);
export const lazyOnManualSyncCalendar = new LazyAction(lazyModule, m => m.onManualSyncCalendar);
export const lazySetApolloClient = new LazyAction(lazyModule, m => m.setApolloClient);
export const lazyApplyCalendarEventsSyncLieForRange = new LazyAction(
    lazyModule,
    m => m.applyCalendarEventsSyncLieForRange
);
