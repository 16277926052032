import type NotificationCallback from './schema/NotificationCallback';
import type NotificationSubscription from './schema/NotificationSubscription';
import { unregisterSubscription } from './subscriptionSubmitter';
import { logUsage } from 'owa-analytics';
import { MailboxBasedSubscriptionTracker } from './subscriptionTracker';
import {
    getGlobalSettingsAccountMailboxInfo,
    getMailboxInfoForSettingsAccounts,
} from 'owa-account-source-list-store';
import { isSignalRChannelLoggingEnabled } from './utils/isSignalRChannelLoggingEnabled';
import { getSupportedConnectionTypesForNotification } from './Subscriptions/supportedNotifications';

let unsubscribeCb: typeof unsubscribe | undefined;
export function setUnsubscribeCb(callback: typeof unsubscribe) {
    unsubscribeCb = callback;
}

export default function unsubscribe(
    subscription: NotificationSubscription,
    callback: NotificationCallback
) {
    if (unsubscribeCb) {
        unsubscribeCb(subscription, callback);
    } else {
        const mailboxInfo = subscription.mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
        const subscribeForAllMailboxes = subscription.subscribeForAllMailboxes ?? false;
        const supportedConnectionTypes = getSupportedConnectionTypesForNotification(
            mailboxInfo,
            subscription.subscriptionParameters.NotificationType
        );
        let mailboxesToProcess = [mailboxInfo];
        if (subscribeForAllMailboxes) {
            mailboxesToProcess = getMailboxInfoForSettingsAccounts();
        }

        for (const mailboxInfoToProcess of mailboxesToProcess) {
            for (const connectionType of supportedConnectionTypes) {
                MailboxBasedSubscriptionTracker.remove(
                    mailboxInfoToProcess,
                    subscription,
                    callback,
                    connectionType
                );
                unregisterSubscription(mailboxInfoToProcess, subscription, connectionType);
                if (isSignalRChannelLoggingEnabled(mailboxInfoToProcess)) {
                    logUsage('SignalRNotification', {
                        SubscriptionId: subscription.subscriptionId,
                        Action: 'Unsubscribe',
                        connectionType,
                    });
                }
            }
        }
    }
}
