import { appAppReboot } from 'owa-data-worker-app-reboot';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { AppDatabase } from 'owa-offline-database';
import { OfflineDisabledReason, toggleOffline } from 'owa-offline-sync-settings';
import { errorThatWillCauseAlert, type TraceErrorObject } from 'owa-trace';
import { incrementDatabaseCorruptionCount } from './incrementDatabaseCorruptionCount';

// Since deleting the database and resyncing is quite expensive, we are only willing to try it a few times
// before we just give up and disable offline.
export const MAX_CORRUPTION_COUNT = 5;

// Make sure we only try to recover from corruption once even if we detected corruption multiple times.
let wasCorruptionHandled: true | undefined;

export function handleDatabaseCorruption(
    database: AppDatabase,
    error: Error,
    isCritical: boolean
): void {
    const shouldAttemptRecovery =
        isCritical ||
        isFeatureEnabled(
            'fwk-offline-corruption',
            undefined /*mailboxInfo*/,
            true /*dontThrowErrorIfNotInitialized*/
        );

    if (!wasCorruptionHandled && shouldAttemptRecovery) {
        wasCorruptionHandled = true;
        const count = incrementDatabaseCorruptionCount();
        if (count <= MAX_CORRUPTION_COUNT) {
            database.delete();
        } else {
            toggleOffline('false', OfflineDisabledReason.DatabaseCorruption);
        }

        (error as TraceErrorObject).diagnosticInfo = `DatabaseCorruptionCount: ${count}`;
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert(error);

        // Reboot the app to get back into a better state
        appAppReboot('DatabaseCorruption', true, { count });
    }
}

export function resetForTest() {
    wasCorruptionHandled = undefined;
}
