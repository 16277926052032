import { AriaCoreDatapoint, VerbosePerfEventType } from '../datapoints/AriaCoreDatapoint';
import type {
    AriaDatapointType,
    CustomDataMap,
    DataPointEventType,
    InternalDatapointOptions,
    CoreAnalyticsOptions,
} from 'owa-analytics-types';
import { getInitializedAnalytics } from '../initializeAnalytics';
import optionallyTraceDatapoint from '../utils/optionallyTraceDatapoint';
import { validatePropertyBag } from '../utils/validatePropertyBag';
import isDevOrAutomationEnvironment from '../utils/isDevOrAutomationEnvironment';
import getFlightControl from '../utils/getFlightControl';
import shouldLogDatapoint from '../utils/shouldLogDatapoint';
import { createEvent, logOneDSDatapoint } from '../OneDsWrapper';
import { isRunningOnWorker } from 'owa-config/lib/isRunningOnWorker';
import isQosDatapoint from '../utils/isQosDatapoint';
import { ValueKind } from '@microsoft/1ds-analytics-js';
import type { IEventProperty } from '@microsoft/1ds-analytics-js';
import { logUnifiedEvents } from './logUnifiedDatapoint';
import { errorThatWillCauseAlertAndThrow } from 'owa-trace';
import { highCountEventThrottle } from '../utils/highCountEventThrottle';
import { getAnalyticsFlightsAndAppSettings } from '../settings/getAnalyticsFlightsAndAppSettings';
import { getAriaTenantToken } from 'owa-analytics-shared/lib/settings/getAriaTenantToken';

const MAX_MISSING_ANALYTICS_OPTIONS_ERRORS = 25;
let missingAnalyticsOptionsCount = 0;

export async function logDatapoint(
    datapoint: AriaDatapointType,
    overrideEventType?: DataPointEventType
) {
    if (isRunningOnWorker()) {
        datapoint = AriaCoreDatapoint.fromJSObject<AriaDatapointType>(datapoint);
    }

    const properties = datapoint.getAllProperties() ?? {};

    const analyticsOptions = getInitializedAnalytics();
    if (!analyticsOptions) {
        // Only log it once per session
        const isNotLogDatapointError = !(properties?.message as string | undefined)?.includes?.(
            'LogDatapointCalledBeforeInitialization'
        );

        // We could get an infinite loop if we throw an errors here, so we limit the number of errors we throw.
        if (
            missingAnalyticsOptionsCount++ < MAX_MISSING_ANALYTICS_OPTIONS_ERRORS &&
            isNotLogDatapointError
        ) {
            const error = properties?.message;
            let message = `Analytics_Addons_LogDatapointCalledBeforeInitialization. IsRunningOnWorker: ${isRunningOnWorker()}. AnalyticsOptionsType: ${typeof analyticsOptions}.`;

            if (error) {
                message += ` Error: ${error}`;
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * Error constructor names can only be a string literals.
                 *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
                const newError = new Error(message);
                const stack = properties?.stack;
                newError.stack = stack as string | undefined;
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * The error name (message) must be a string literal (no variables in it).
                 *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                errorThatWillCauseAlertAndThrow(message, newError);
            } else {
                message += ` EventName: ${datapoint.getEventName()}`;
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * The error name (message) must be a string literal (no variables in it).
                 *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                errorThatWillCauseAlertAndThrow(message);
            }
        }
        return;
    }

    const eventType = getEventType(datapoint, overrideEventType);
    const flightControl = getFlightControl(datapoint, analyticsOptions, eventType);

    // While gathering data, we do not throttle the events
    if (getAnalyticsFlightsAndAppSettings().enableHighCountEventThrottle) {
        highCountEventThrottle(
            datapoint,
            getAnalyticsFlightsAndAppSettings().highCountEventThrottleWindow,
            getAnalyticsFlightsAndAppSettings().highCountEventThrottleThreshold
        );
    }

    if (
        !(await shouldLogDatapoint(datapoint, analyticsOptions, eventType)) &&
        !getAnalyticsFlightsAndAppSettings().isFullVerboseLoggingEnabled
    ) {
        // This event will not be logged in the "Outlook Web" tenant, but will be logged in the UT one
        await logUnifiedEvents(datapoint, analyticsOptions, eventType);
        return;
    }

    properties.EventType = 'standard';
    if (isQosDatapoint(datapoint)) {
        properties.EventType = 'qos';
        const { throughAFD, serviceVersion, dag } = analyticsOptions.startupInfo;
        properties.ThroughAFD = throughAFD;
        properties.ServiceVersion = serviceVersion;
        properties.Dag = dag;
    }

    if (getAnalyticsFlightsAndAppSettings().isFullVerboseLoggingEnabled) {
        properties['OriginalEvent'] = eventType;
    }

    properties['Sampled'] = flightControl?.rate ? flightControl.rate : 100;

    const propertyBag = datapoint.getPropertyBag();
    if (propertyBag) {
        properties['MiscData'] = validatePropertyBag(
            propertyBag,
            datapoint.getEventName(),
            !!getAnalyticsFlightsAndAppSettings().turnOffCustomData
        );
    }

    optionallyTraceDatapoint(datapoint);

    // let's not actually log datapoints if we are gulping or in a branch or a tds box
    // unless the datapoint is explicitly whitelisted
    if (!isDevOrAutomationEnvironment() || eventType == 'client_event_dev_only') {
        const datapointOptions = datapoint.getOptions() as InternalDatapointOptions;
        const token = getAriaTenantToken(
            <string | undefined>datapoint.getData('App') ?? 'Mail',
            datapointOptions?.tenantOverride
        );
        if (token) {
            await logEvent(token, datapoint, properties, eventType, analyticsOptions);
        }
    }

    // Log as Unified Event if datapoint matches ECS catalogs
    await logUnifiedEvents(datapoint, analyticsOptions, eventType);
}

async function logEvent(
    tenantToken: string,
    datapoint: AriaDatapointType,
    properties: CustomDataMap,
    eventType: DataPointEventType,
    analyticsOptions: CoreAnalyticsOptions,
    updateAppSettings?: boolean
) {
    const e = createEvent(
        getAnalyticsFlightsAndAppSettings().isFullVerboseLoggingEnabled
            ? VerbosePerfEventType
            : eventType,
        properties,
        datapoint.getCreationTime()
    );

    if (properties['E2ETimeElapsed']) {
        e.latency = <number>properties['E2ETimeElapsed'];
    }

    const piiData = datapoint.getPiiData?.();
    if (e.data && piiData) {
        e.data['PiiData'] = <IEventProperty>{
            value: piiData,
            kind: ValueKind.Pii_Identity,
        };
    }

    await logOneDSDatapoint({
        tenantToken,
        item: e,
        analyticsOptions,
        updateAppSettings,
    });
}

function getEventType(
    datapoint: AriaDatapointType,
    overrideEventType: DataPointEventType | undefined
): DataPointEventType {
    if (overrideEventType) {
        return overrideEventType;
    }

    const datapointOptions = datapoint.getOptions() as InternalDatapointOptions;

    if (datapointOptions?.isGreyError) {
        return 'client_grey_error';
    }

    if (datapointOptions?.isVerbose) {
        return 'client_verbose';
    }

    if (datapointOptions?.excludeFromKusto) {
        return 'client_cosmos';
    }

    return 'client_event';
}
