import { trace } from 'owa-trace';
import { lazyStartAccountSyncEngine, lazyStopAccountSyncEngine } from 'owa-offline-initialize-sync';
import type { CoprincipalAccountSource } from 'owa-account-source-list-store';

/**
 * Checks the persistence id both before and after and update for any changes
 * @param accountsBefore Map of coprincipal account info keys by persistenceIds before the sync
 * @param accountsAfter Map of coprincipal account info keys by persistenceIds before the sync
 */
export default function checkForAccountChanges(
    accountsBefore: CoprincipalAccountSource[],
    accountsAfter: CoprincipalAccountSource[]
) {
    const persistenceIdsInAfter = new Set<string>(
        accountsAfter.map(account => account.persistenceId || '')
    );
    const persistenceIdsInBoth = new Set<string>();
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    accountsBefore.forEach(before => {
        if (persistenceIdsInAfter.has(before.persistenceId || '')) {
            persistenceIdsInBoth.add(before.persistenceId || '');
        }
    });

    // Any accounts that are in the after but not in both are added accounts
    const added = accountsAfter.filter(
        account => !persistenceIdsInBoth.has(account.persistenceId || '')
    );

    // Any accounts that are in the before but not in both were removed
    const removed = accountsBefore.filter(
        account => !persistenceIdsInBoth.has(account.persistenceId || '')
    );

    if (added.length !== 0 || removed.length !== 0) {
        const addedIds = added.map(account => account.persistenceId).join(';');
        const removedIds = removed.map(account => account.persistenceId).join(';');
        trace.info(`[account-sync] Accounts changed. added (${addedIds}), removed (${removedIds})`);

        added.map(account => lazyStartAccountSyncEngine.importAndExecute(account));
        removed.map(account =>
            lazyStopAccountSyncEngine.importAndExecute(account, 'account removed')
        );
    }
}
