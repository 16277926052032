import { loadSyncState, saveSyncState } from 'owa-offline-sync-state';
import type { SyncModuleState } from 'owa-offline-sync-state-schema';
import type { FoldersSyncState } from '../FoldersSyncState';
import { FOLDERS_SYNC_STATE_NAME } from '../FoldersSyncState';
import type { MailboxInfo } from 'owa-client-types';
import { getDatabase } from 'owa-offline-database';

export async function saveOfflineFolderHierarchySyncState(
    mailboxInfo: MailboxInfo,
    includesLastItemInRange: boolean,
    customSorted: boolean
) {
    // Write the updated sync state
    const database = getDatabase(mailboxInfo);
    await database.transaction('rw', database.syncState, async () => {
        const syncModuleState: SyncModuleState<FoldersSyncState> | undefined = await loadSyncState(
            database,
            FOLDERS_SYNC_STATE_NAME
        );
        if (syncModuleState) {
            // If we now have the full folder hiearchy, mark it as such in the sync state
            if (includesLastItemInRange) {
                syncModuleState.syncState.includesLastFolderInRange = true;
            }

            // Update the custom sorted prop
            syncModuleState.syncState.customSorted = customSorted;

            await saveSyncState(database, syncModuleState);
        }
    });
}
