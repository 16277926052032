import type NotificationCallback from './schema/NotificationCallback';
import type NotificationSubscription from './schema/NotificationSubscription';
export interface SubscriptionInformation {
    notificationSubscription: NotificationSubscription;
    notificationCallback: NotificationCallback;
}
export abstract class MultiAccountSubscriptions {
    public static multiAccountSubscriptions: SubscriptionInformation[] = [];

    public static addSubscription(
        subscription: NotificationSubscription,
        callback: NotificationCallback
    ) {
        MultiAccountSubscriptions.multiAccountSubscriptions.push({
            notificationSubscription: subscription,
            notificationCallback: callback,
        });
    }

    public static getSubscriptions(): SubscriptionInformation[] {
        return MultiAccountSubscriptions.multiAccountSubscriptions;
    }
}
