import type NotificationCallback from './schema/NotificationCallback';
import type NotificationSubscription from './schema/NotificationSubscription';
import {
    initialSubscriptionTrackerState,
    type SubscriptionStatus,
} from './schema/SubscriptionTrackerState';
import { emitWarn } from './utils/emitTrace';
import type {
    SubscriptionReference,
    SubscriptionState,
    SubscriptionTrackerState,
} from './schema/SubscriptionTrackerState';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { ConnectionType } from './HubConnection/utils/ConnectionType';

export function getSubscriptionIdStateKey(
    subscriptionId: string,
    connectionType: ConnectionType
): string {
    return subscriptionId + '_' + connectionType;
}

export abstract class MailboxBasedSubscriptionTracker {
    public static mailboxBasedSubscriptions: Map<string, SubscriptionTracker> = new Map<
        string,
        SubscriptionTracker
    >();

    public static getSubscriptions(
        mailboxInfo: MailboxInfo,
        connectionType: ConnectionType
    ): NotificationSubscription[] {
        const tracker = MailboxBasedSubscriptionTracker.getTracker(
            mailboxInfo,
            true /* createIfNotExists */
        );
        if (tracker) {
            return tracker.getSubscriptions(connectionType);
        }
        return [];
    }

    public static getSubscription(
        mailboxInfo: MailboxInfo,
        subscriptionId: string,
        connectionType: ConnectionType
    ): NotificationSubscription | undefined {
        const tracker = MailboxBasedSubscriptionTracker.getTracker(
            mailboxInfo,
            false /* createIfNotExists */
        );
        return tracker?.getSubscriptionState(subscriptionId, connectionType)?.refs[0].subscription;
    }

    public static getHandlers(
        mailboxInfo: MailboxInfo,
        subscriptionId: string,
        connectionType: ConnectionType
    ): NotificationCallback[] {
        return MailboxBasedSubscriptionTracker.getRefs(
            mailboxInfo,
            subscriptionId,
            connectionType
        ).map(ref => ref.callback);
    }

    public static getRefs(
        mailboxInfo: MailboxInfo,
        subscriptionId: string,
        connectionType: ConnectionType
    ): SubscriptionReference[] {
        const tracker = MailboxBasedSubscriptionTracker.getTracker(
            mailboxInfo,
            true /* createIfNotExists */
        );
        if (tracker) {
            return tracker.getRefs(subscriptionId, connectionType);
        }
        return [];
    }

    public static add(
        mailboxInfo: MailboxInfo,
        subscription: NotificationSubscription,
        callback: NotificationCallback,
        connectionType: ConnectionType
    ): void {
        const tracker = MailboxBasedSubscriptionTracker.getTracker(
            mailboxInfo,
            true /* createIfNotExists */
        );
        if (tracker) {
            tracker.add(subscription, connectionType, callback);
        } else {
            emitWarn(
                `Tried to add subscription for mailbox ${mailboxInfo.sourceId} when it has not been initialized yet`
            );
            return;
        }
    }

    public static remove(
        mailboxInfo: MailboxInfo,
        subscription: NotificationSubscription,
        callback: NotificationCallback,
        connectionType: ConnectionType
    ): void {
        const tracker = MailboxBasedSubscriptionTracker.getTracker(mailboxInfo);
        if (tracker) {
            tracker.remove(subscription, connectionType, callback);
        } else {
            emitWarn(
                `Tried to remove subscription for mailbox ${mailboxInfo.sourceId} when it has not been initialized yet`
            );
            return;
        }
    }

    public static getSubscriptionState(
        mailboxInfo: MailboxInfo,
        subscriptionId: string,
        connectionType: ConnectionType
    ): SubscriptionState | undefined {
        const tracker = MailboxBasedSubscriptionTracker.getTracker(mailboxInfo);
        if (tracker) {
            return tracker.getSubscriptionState(subscriptionId, connectionType);
        }
        return undefined;
    }

    public static hasSubscription(
        mailboxInfo: MailboxInfo,
        subscriptionId: string,
        connectionType: ConnectionType
    ): boolean {
        const tracker = MailboxBasedSubscriptionTracker.getTracker(mailboxInfo);
        if (tracker) {
            return tracker.hasSubscription(subscriptionId, connectionType);
        } else {
            MailboxBasedSubscriptionTracker.initializeForMailbox(mailboxInfo);
        }
        return false;
    }

    public static initializeForMailbox(mailboxInfo: MailboxInfo): SubscriptionTracker {
        const tracker = new SubscriptionTracker();
        MailboxBasedSubscriptionTracker.mailboxBasedSubscriptions.set(
            getIndexerValueForMailboxInfo(mailboxInfo),
            tracker
        );
        return tracker;
    }

    public static getTracker(
        mailboxInfo: MailboxInfo,
        createIfNotExists?: boolean
    ): SubscriptionTracker | undefined {
        const tracker = MailboxBasedSubscriptionTracker.mailboxBasedSubscriptions.get(
            getIndexerValueForMailboxInfo(mailboxInfo)
        );
        if (!tracker && createIfNotExists) {
            return MailboxBasedSubscriptionTracker.initializeForMailbox(mailboxInfo);
        }
        return tracker;
    }
}

export class SubscriptionTracker {
    public initialized: boolean;
    public state: SubscriptionTrackerState;

    public constructor() {
        this.state = initialSubscriptionTrackerState();
        this.initialized = true;
    }

    public getSubscriptions(connectionType: ConnectionType): NotificationSubscription[] {
        return Object.values<SubscriptionState>(this.state).reduce((arr, subState) => {
            if (subState.source === connectionType) {
                arr.push(subState.refs[0].subscription);
            }
            return arr;
        }, [] as NotificationSubscription[]);
    }

    public getHandlers(
        subscriptionId: string,
        connectionType: ConnectionType
    ): NotificationCallback[] {
        return this.getRefs(subscriptionId, connectionType).map(ref => {
            return ref.callback;
        });
    }

    public getRefs(
        subscriptionId: string,
        connectionType: ConnectionType
    ): SubscriptionReference[] {
        return this.hasSubscription(subscriptionId, connectionType)
            ? this.state[getSubscriptionIdStateKey(subscriptionId, connectionType)].refs
            : [];
    }

    public add(
        subscription: NotificationSubscription,
        connectionType: ConnectionType,
        callback: NotificationCallback
    ): void {
        const subscriptionIdStateKey = getSubscriptionIdStateKey(
            subscription.subscriptionId,
            connectionType
        );
        if (!this.hasSubscription(subscription.subscriptionId, connectionType)) {
            // First time we're seeing this subscription ID
            this.state[subscriptionIdStateKey] = {
                refs: [],
                status: 0,
                retries: 0,
                pendingRetryHandle: 0,
                source: connectionType,
            };
        }

        // Add this ref to the cache
        this.state[subscriptionIdStateKey].refs.push({
            subscription,
            callback,
        });
    }

    public remove(
        subscription: NotificationSubscription,
        connectionType: ConnectionType,
        callback: NotificationCallback
    ): void {
        if (!this.hasSubscription(subscription.subscriptionId, connectionType)) {
            // Called remove on an untracked subscription
            emitWarn(
                `Tried to remove ${subscription.subscriptionId} from the subscription tracker when it didn't exist`
            );

            return;
        }

        const subscriptionIdStateKey = getSubscriptionIdStateKey(
            subscription.subscriptionId,
            connectionType
        );

        const refs = this.state[subscriptionIdStateKey].refs;

        for (let i = 0; i < refs.length; i++) {
            if (refs[i].callback === callback) {
                refs.splice(i, 1);
                break;
            }
        }

        if (refs.length === 0) {
            // If there was a pending retry, cancel it
            if (this.state[subscriptionIdStateKey].pendingRetryHandle !== 0) {
                self.clearTimeout(this.state[subscriptionIdStateKey].pendingRetryHandle);
            }

            // That was the last callback for that id, stop tracking the subscription
            delete this.state[subscriptionIdStateKey];
        }
    }

    public getSubscriptionState(
        subscriptionId: string,
        connectionType: ConnectionType
    ): SubscriptionState {
        return this.state[getSubscriptionIdStateKey(subscriptionId, connectionType)];
    }

    public hasSubscription(subscriptionId: string, connectionType: ConnectionType): boolean {
        return getSubscriptionIdStateKey(subscriptionId, connectionType) in this.state;
    }
}
