import type { Transaction } from 'dexie';
import { getSystemDatabase } from 'owa-offline-system-database';
import type { WorkerLeaderType, WorkerLeaderTable } from 'owa-offline-worker-leader-schema';
import { WORKER_LEADER_TABLE_NAME } from 'owa-offline-worker-leader-schema';
import { newGuid } from 'owa-config';

export type ElectionResultCallbacks = {
    onElected: () => void;
    onDeposed: () => void;
};

export const KEEP_ALIVE_INTERVAL_MS = 5000;
export const ELECT_INTERVAL_MS = KEEP_ALIVE_INTERVAL_MS * 3;

let registration: ElectionResultCallbacks;
let myId: string;
let myCurrentLeader: string;

export function elect(tx?: Transaction) {
    if (!myId) {
        return Promise.resolve();
    } else {
        return tx
            ? internalElect(tx)
            : getSystemDatabase().transaction('rw', WORKER_LEADER_TABLE_NAME, internalElect);
    }
}

async function internalElect(tx: Transaction) {
    const workerLeaderTable: WorkerLeaderTable = tx.table(WORKER_LEADER_TABLE_NAME);

    // store this so we know if my leader changed during this election
    const leaderBeforeElection = myCurrentLeader;

    // make sure we're alive
    await keepAlive();

    // garbage collect
    const aliveThreshold = Date.now() - ELECT_INTERVAL_MS;
    const allWorkers: Array<WorkerLeaderType> = await workerLeaderTable.toArray();
    const deadWorkerIds = allWorkers.filter(w => w.lastSeen < aliveThreshold).map(w => w.uuid);
    await workerLeaderTable.bulkDelete(deadWorkerIds);

    // see if there's a consensus leader.  the definitive active leader is the one according to the database.
    const livingWorkers: Array<WorkerLeaderType> = (await workerLeaderTable.toArray()) || [];
    const consensusLeader = livingWorkers.filter(w => w.isLeader)?.[0]?.uuid;

    if (consensusLeader) {
        // if there is, keep it
        myCurrentLeader = consensusLeader;
    } else {
        // carpe diem, it's me!
        await workerLeaderTable.put({
            uuid: myId,
            isLeader: true,
            lastSeen: Date.now(),
        });
        myCurrentLeader = myId;
    }

    // if we changed leaders, we should notify there was a new election
    // done via set timeout so that the handlers do not run as part of the transaction
    if (leaderBeforeElection != myCurrentLeader) {
        setTimeout(() => notifyElection(leaderBeforeElection || '', myCurrentLeader), 0);
    }
}

export function isLeader(): boolean {
    return !!myId && myId === myCurrentLeader;
}

export function isFollower(): boolean {
    return !!myId && !!myCurrentLeader && myId !== myCurrentLeader;
}

export async function register(cb: ElectionResultCallbacks): Promise<string> {
    return getSystemDatabase().transaction('rw', WORKER_LEADER_TABLE_NAME, async tx => {
        const workerLeaderTable: WorkerLeaderTable = tx.table(WORKER_LEADER_TABLE_NAME);

        myId = await workerLeaderTable.put({
            uuid: newGuid(),
            isLeader: false,
            lastSeen: Date.now(),
        });

        registration = cb;

        return myId;
    });
}

export function notifyElection(previous: string, current: string) {
    if (current == myId && previous != myId) {
        registration.onElected();
    } else if (previous == myId && current != myId) {
        registration.onDeposed();
    }
}

export async function keepAlive() {
    if (!myId) {
        return Promise.resolve();
    } else {
        return getSystemDatabase().transaction('rw', WORKER_LEADER_TABLE_NAME, async tx => {
            const workerLeaderTable: WorkerLeaderTable = tx.table(WORKER_LEADER_TABLE_NAME);

            const updated = await workerLeaderTable.update(myId, { lastSeen: Date.now() });
            if (!updated) {
                // this will happen if another worker thought we disappeared and deleted us from the database
                await workerLeaderTable.put(
                    { uuid: myId, isLeader: false, lastSeen: Date.now() },
                    myId
                );
            }
        });
    }
}
