import {
    ClientIdPrefix,
    ConversationIdPrefix,
    ItemIdPrefix,
    ParentFolderIdPrefix,
} from 'owa-identifiers';
import { getGuid } from 'owa-guid';

export function extractRealIdFromClientId(clientId: string) {
    if (isClientConversationId(clientId)) {
        const extractedIds = extractConversationInputId(clientId);
        return {
            isConversation: true,
            parentFolderId: extractedIds[0],
            extractedId: extractedIds[1],
        };
    } else if (isClientItemId(clientId)) {
        return {
            isConversation: false,
            extractedId: extractItemInputId(clientId),
        };
    }

    return undefined;
}

export function createNewInstanceKey() {
    return getGuid();
}

export function tryCreateClientItemId(serverItemId: string) {
    // In case the Id is already a temporary Id, dont add any prefix
    const isTemporaryId = isClientItemId(serverItemId);

    // If it is already a temporary id, change the guid part only
    if (isTemporaryId) {
        return updateGuid(serverItemId);
    }

    return `${ClientIdPrefix}${ItemIdPrefix}${serverItemId}:${getGuid()}`;
}

export function tryCreateClientConversationId(
    serverConversationId: string,
    parentFolderId: string
) {
    const isTemporaryId = isClientConversationId(serverConversationId);

    // In case the Id is already a temporary Id, dont add any prefix
    if (isTemporaryId) {
        return updateGuid(serverConversationId);
    }

    return `${ClientIdPrefix}${ParentFolderIdPrefix}${parentFolderId}:${ConversationIdPrefix}${serverConversationId}:${getGuid()}`;
}

export function isClientItemId(itemId: string) {
    return itemId.indexOf(ItemIdPrefix) >= 0;
}

export function isClientConversationId(conversationId: string) {
    return conversationId.indexOf(ConversationIdPrefix) >= 0;
}

function updateGuid(temporaryId: string) {
    // Split the string by ':'
    const parts = temporaryId.split(':');

    // Remove last part, which will be the guid
    const partsWithoutGuid = parts.slice(0, parts.length - 1);

    // Join the extracted parts with ':' and add the new guid
    return `${partsWithoutGuid.join(':')}:${getGuid()}`;
}

function extractItemInputId(clientId: string) {
    // Split the string by ':'
    const parts = clientId.split(':');

    // Extracted id is the temporary id by removing the clientId, itemId prefixes, and the guid
    return parts[2];
}

function extractConversationInputId(clientId: string) {
    // Split the string by ':'
    const parts = clientId.split(':');

    // Extracted id is the temporary id by removing the clientId, parentFolderId, conversationId prefixes, and the guid
    return [parts[2], parts[4]];
}
