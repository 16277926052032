import { bootstrap } from './bootstrap';
import type {
    OnAuthFailedCb,
    GetAuthTokenCb,
    PrepareConnectedAccountRequestOptionsCb,
    GetResourceTokenCb,
    GetUserTokenCb,
    GetMsalTokenCb,
    GetWebSessionTypeCb,
    PrepareRequestOptionsCb,
    GetTimeZoneAndLanguageCb,
    GraphQLWorkerRequestCallbacks,
    NotificationManagerWorkerCallbacks,
    GetAnchorMailboxCb,
    GetExplicitLogonCb,
    GetMailboxRequestTypeCb,
    GetUpdatedRequestSettingsCb,
    BasicHeader,
    DataWorkerSettings,
    OnActivityTimeoutErrorCb,
    OnPinnedAppsChangedCb,
    GetModuleContextMailboxInfoCb,
} from 'owa-data-worker-utils';
import {
    toBasicHeaders,
    fromBasicOptions,
    toBasicOptions,
    initTimings,
    sanitize,
} from 'owa-data-worker-utils';
import {
    type GlobalFeatureName,
    isAccountFeatureEnabled,
    isFeatureEnabled,
    type AccountFeatureName,
} from 'owa-feature-flags';
import { getLocalStorageOverrides } from 'owa-feature-flags/lib/utils/overrides/localStorageOverrides';
import { setPreAndPostAccountLoadLookupHelpers } from 'owa-account-source-list/lib/utils/initializeLookupHelpers';
import { initializeApplicationSettings } from 'owa-application-settings';
import { updateServiceConfig } from 'owa-service/lib/config';
import type { ServiceConfigData, ServiceConfigDataCacheKey } from '../util/serviceConfigData';
import {
    getServiceConfigData,
    undefinedSymbol,
    clearServiceConfigData,
    updateServiceConfigData,
    ServiceConfigCacheBehavior,
} from '../util/serviceConfigData';
import { getIsUserIdle as isUserIdle } from '../util/idle';
import type { RequestOptions, HeadersWithoutIterator } from 'owa-service/lib/RequestOptions';
import { setApp } from 'owa-config/lib/bootstrapOptions';
import { isRunningInMetaOSHub, setHostHub, setDatetimeBootstrapOptions } from 'owa-config';
import { getBroadcast } from '../broadcast/getBroadcast';
import { setUserConfiguration } from './setUserConfiguration';
import { getLocalizationContext } from 'owa-shared-bootstrap/lib/getLocalizationContext';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import { initializeOwaLocalization } from 'owa-localization-bootstrap';
import type { MailboxInfo } from 'owa-client-types';
import { setGraphQlCallbacks } from '../util/graphqlCallbacks';
import { setNotificationCallbacks } from '../actions/notifySubscription';
import setSessionStoreUserConfig from 'owa-session-store/lib/actions/setUserConfiguration';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type { SyncEventsListener } from 'owa-offline-sync-diagnostics';
import type { PerformanceDatapointObjectType } from 'owa-analytics-types';
import { lazyRegisterOwsCallbacks, logGreyError } from 'owa-analytics';
import type { MailboxRequestType } from 'owa-request-options';
import { getRequestOptionsOrDefault } from 'owa-headers-core';
import { lazyPurgeImagesFromDatabase } from 'owa-image-database-cleanup';
import { intializeSyncEventsListener } from './initializeSyncEventsListener';
import { setAccountSourceListStoreData } from '../util/setAccountSourceListStoreData';
import type { WindowBootstrapSettings } from 'owa-worker-types';
import { setLocalStorageCallbacks } from 'owa-local-storage/lib/util/workerLocalStorageCallbacks';
import type {
    LocalStorageRemoveItemCb,
    LocalStorageSetItemCb,
} from 'owa-local-storage/lib/util/workerLocalStorageCallbacks';
import { registerAnalyticsDataWorkerAddons } from 'owa-analytics-addons-data-worker';
import { setGreyErrorFunc, setRegistrationCompleted } from 'owa-analytics-addons';
import { initFeatures } from 'owa-worker-shared/lib/features/initFeatures';
import { patchChunkLoad } from '../util/patchChunkLoad';
import { setOptionsStoreData } from '../util/setOptionsStoreData';
import {
    setUpdateRequestSettingCallback,
    updateRequestSettings,
} from '../util/updateRequestSettings';
import { setRebootWorkerCallback } from 'owa-data-worker-app-reboot';
import type { AppRebootCb } from 'owa-data-worker-app-reboot';

import { trySetBposNavBarData } from 'owa-bpos-store';
import { isGetMailboxSpecificRequestOptionsV2Enabled } from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';
import { getCanaryHeadersImpl } from 'owa-service/lib/setCanaryHeader';
import throttle from 'lodash-es/throttle';
import { lazyGetMailboxSpecificRequestOptionsFromMailboxInfo } from 'owa-request-options/lib/utils/lazyGetMailboxSpecificRequestOptionsV2';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { getTokenCache } from 'owa-tokenprovider';
import { setWin32GqlHostObjectCb, type Win32GqlHostObjectCb } from 'native-win32gql-service';
import { lazyPurgeAttachmentsFromDatabase } from 'owa-opening-attachments-cleanup';
import type MailboxRequestOptions from 'owa-service/lib/MailboxRequestOptions';
import { initializeErrorListener } from '../util/initializeErrorListener';
import { updateWorkerConfig } from 'owa-worker-shared/lib/utils/workerConfig';
import { initializeApolloClientOnWorker } from 'owa-apollo';
import { getWorkerContextClient } from '../util/getWorkerContextClient';
import { getApplicationSettings, type ApplicationSettingGroup } from 'owa-application-settings';

const diagnosticHeaders = ['request-id', 'client-request-id', 'x-ms-diagnostics'];

let isInitialized: boolean = false;
const pendingInitializationCallbacks: (() => void)[] = [];

export function getIsInitialized() {
    return isInitialized;
}

export async function waitForPendingInitialization(): Promise<void> {
    return new Promise<void>((resolve, _reject) => {
        if (isInitialized) {
            resolve();
        } else {
            pendingInitializationCallbacks.push(() => {
                resolve();
            });
        }
    });
}

export async function initialize(
    rawData: WindowBootstrapSettings,
    settings: DataWorkerSettings,
    graphqlCb: GraphQLWorkerRequestCallbacks,
    getAuthTokenCb: GetAuthTokenCb,
    getResourceTokenCb: GetResourceTokenCb,
    getUserTokenCb: GetUserTokenCb,
    getWebSessionType: GetWebSessionTypeCb,
    getMsalTokenCb: GetMsalTokenCb,
    onAuthFailedCb: OnAuthFailedCb,
    getAnchorMailboxCb: GetAnchorMailboxCb | undefined,
    getExplicitLogonCb: GetExplicitLogonCb | undefined,
    prepareRequestOptionsCb: PrepareRequestOptionsCb | undefined,
    notificationCallbacks: NotificationManagerWorkerCallbacks,
    syncEventsListener: SyncEventsListener | undefined,
    getMailboxRequestTypeCb: GetMailboxRequestTypeCb,
    setLocalStorageItemCb: LocalStorageSetItemCb,
    removeLocalStorageItemCb: LocalStorageRemoveItemCb,
    getUpdatedRequestDataCb: GetUpdatedRequestSettingsCb,
    appRebootCb: AppRebootCb,
    win32GqlCb: Win32GqlHostObjectCb | undefined,
    prepareConnectedAccountRequestOptionsCb: PrepareConnectedAccountRequestOptionsCb | undefined,
    onActivityTimeoutErrorCb: OnActivityTimeoutErrorCb,
    getTimeZoneAndLanguageCb: GetTimeZoneAndLanguageCb,
    onPinnedAppsChangedCb: OnPinnedAppsChangedCb,
    getModuleContextMailboxInfoCb: GetModuleContextMailboxInfoCb
): Promise<PerformanceDatapointObjectType> {
    // bootstrap should be the VERY first thing called (trust me)
    bootstrap(rawData, settings.sessionId);
    initTimings.mark('Initialize_WS');

    // all delay loads should be done from here (or not at all)
    // the worker thread uses importScripts to load files, which blocks the worker thread
    // and that shouldn't happen during initialize.
    const delayLoadedScripts: (() => Promise<void>)[] = [];

    // core infra initialized from session data, like features, settings, config
    const sd = settings.sessionData;
    const { owaUserConfig } = sd;

    setDatetimeBootstrapOptions(settings.datetimeOptions);

    const hasBposData = owaUserConfig?.NavBarData && settings.app !== 'Bookings';
    const isConsumer = owaUserConfig?.SessionSettings?.WebSessionType !== 0;

    if (hasBposData || isConsumer) {
        trySetBposNavBarData(owaUserConfig?.NavBarData ?? null);
    }

    // set up account source list store before we set the feture flags and app settings, this allows
    // for the feature flags and app settings packages to have access to the global settings account
    // in the owa-account-source-list-store
    setPreAndPostAccountLoadLookupHelpers();
    setAccountSourceListStoreData(settings.accountStore);

    // local storage callbacks should be set before features are initialized because
    // initFeatures now expects local storage callbacks to exist
    setLocalStorageCallbacks(setLocalStorageItemCb, removeLocalStorageItemCb);
    initFeatures(sd, getLocalStorageOverrides());

    setRebootWorkerCallback(appRebootCb);

    initializeErrorListener();

    if (isFeatureEnabled('fwk-increaseStackTraceLimit')) {
        Error.stackTraceLimit = 20;
    }

    initializeApplicationSettings(sd.applicationSettings);
    initTimings.mark('Initialize_W_Settings');

    // Once we finish this experiment we can should move this code into bootstrap
    // the reason it's here is to have Flights available
    if (isFeatureEnabled('fwk-worker-scripts-prefetch')) {
        patchChunkLoad();
    }

    await initializeLocalization(owaUserConfig);
    initTimings.mark('Initialize_W_Loc');

    setApp(settings.app);

    setGreyErrorFunc(logGreyError);
    registerAnalyticsDataWorkerAddons();

    // This will mark the end of the registration and flush the queue
    setRegistrationCompleted('DataWorker');

    // Register client_network_request logging
    delayLoadedScripts.push(lazyRegisterOwsCallbacks.importAndExecute);

    // multi account lists
    setSessionStoreUserConfig(owaUserConfig);
    setUserConfiguration(owaUserConfig);

    // options store
    setOptionsStoreData(settings.optionStore);

    initTimings.mark('Initialize_W_MAcct');

    // service config
    initServiceConfig(
        settings,
        getAuthTokenCb,
        getResourceTokenCb,
        getUserTokenCb,
        getWebSessionType,
        getMsalTokenCb,
        onAuthFailedCb,
        getAnchorMailboxCb,
        getExplicitLogonCb,
        prepareRequestOptionsCb,
        getMailboxRequestTypeCb,
        onActivityTimeoutErrorCb,
        onPinnedAppsChangedCb,
        getModuleContextMailboxInfoCb
    );

    // Worker config
    initWorkerConfig(prepareConnectedAccountRequestOptionsCb, getTimeZoneAndLanguageCb);

    // set up notification manager proxying
    setNotificationCallbacks(notificationCallbacks, settings.channelId);

    // set up sync diagnostics proxying
    if (syncEventsListener) {
        intializeSyncEventsListener(syncEventsListener);
    }

    // initialize the broadcast listener
    getBroadcast();

    // set execution callbacks for graphql requests
    setGraphQlCallbacks(graphqlCb);

    // set request settings callback
    setUpdateRequestSettingCallback(getUpdatedRequestDataCb);

    // set win32gql host object proxy (web workers don't yet support host objects)
    if (win32GqlCb) {
        setWin32GqlHostObjectCb(win32GqlCb);
    }

    if (isRunningInMetaOSHub()) {
        const { metaOSContext } = settings.serviceConfig;
        setHostHub(metaOSContext?.hostName ?? '', metaOSContext?.clientType ?? '');
    }

    // clean up image database if needed.
    if (isFeatureEnabled('fwk-image-database')) {
        // Purge images from database immediately and then every hour
        delayLoadedScripts.push(lazyPurgeImagesFromDatabase.importAndExecute);
        setInterval(
            () => lazyPurgeImagesFromDatabase.importAndExecute(),
            60 * 60 * 1000 // Run every hour
        );
    }

    if (isFeatureEnabled('doc-mon-opening-attachments')) {
        delayLoadedScripts.push(lazyPurgeAttachmentsFromDatabase.importAndExecute);
        setInterval(
            () => lazyPurgeAttachmentsFromDatabase.importAndExecute(),
            60 * 60 * 1000 // Run every hour
        );
    }

    // allows code to call query/mutate exports from owa-apollo
    initializeApolloClientOnWorker(await getWorkerContextClient());

    initTimings.mark('Initialize_WE');
    self.setTimeout(() => {
        delayLoadedScripts.forEach(s => {
            self.setTimeout(() => {
                s().catch(() => {
                    /* ignore errors */
                });
            }, 1000);
        });
    }, 20000);

    const datapointObject = initTimings.getDp().toJSObject();
    isInitialized = true;

    // resolve any pending initialization callbacks but do not block the ack back to the main thread
    self.setTimeout(() => {
        pendingInitializationCallbacks.forEach(cb => cb());
        pendingInitializationCallbacks.length = 0;
    }, 0);

    return Promise.resolve(datapointObject);
}

async function initializeLocalization(owaUserConfig: OwaUserConfiguration) {
    const { locale, culture, dir } = getLocalizationContext(owaUserConfig);
    await initializeOwaLocalization(locale, culture, dir);
}

function initServiceConfig(
    settings: DataWorkerSettings,
    getAuthTokenCb: GetAuthTokenCb,
    getResourceTokenCb: GetResourceTokenCb,
    getUserTokenCb: GetUserTokenCb,
    getWebSessionTypeCb: GetWebSessionTypeCb,
    getMsalTokenCb: GetMsalTokenCb,
    onAuthFailedCb: OnAuthFailedCb,
    getAnchorMailboxCb: GetAnchorMailboxCb | undefined,
    getExplicitLogonCb: GetExplicitLogonCb | undefined,
    prepareRequestOptionsCb: PrepareRequestOptionsCb | undefined,
    getMailboxRequestTypeCb: GetMailboxRequestTypeCb,
    onActivityTimeoutErrorCb: OnActivityTimeoutErrorCb,
    onPinnedAppsChangedCb: OnPinnedAppsChangedCb,
    getModuleContextMailboxInfoCb: GetModuleContextMailboxInfoCb
) {
    const config = settings.serviceConfig;

    const getMailboxRequestType = async (mailboxInfo: MailboxInfo) => {
        return getCachedData<MailboxRequestType>(
            'mailboxRequestType',
            { mailboxInfo },
            () => {
                return getMailboxRequestTypeCb(sanitize(mailboxInfo));
            },
            ServiceConfigCacheBehavior.cacheOnly
        );
    };

    const proxyPrepareRequestOptions = (
        requestOptions: RequestOptions | undefined
    ): Promise<RequestOptions | null | undefined> => {
        return prepareRequestOptionsCb && requestOptions
            ? proxyPrepareRequestOptionsAsync(prepareRequestOptionsCb, requestOptions)
            : Promise.resolve(null);
    };

    const proxyPrepareRequestOptionsAsync = async (
        prepareCb: PrepareRequestOptionsCb,
        requestOptions: RequestOptions
    ) => {
        const mailboxInfo = requestOptions?.mailboxInfo;
        if (!mailboxInfo) {
            return requestOptions || null;
        } else {
            if (isGetMailboxSpecificRequestOptionsV2Enabled()) {
                // Attempt to get the request options from the mailbox info without needing to proxy to main
                const optionsV2 =
                    lazyGetMailboxSpecificRequestOptionsFromMailboxInfo.importAndExecute(
                        requestOptions
                    );
                if (optionsV2 !== undefined) {
                    return { ...optionsV2, datapoint: requestOptions.datapoint };
                }
            }

            const requestType = await getMailboxRequestType(mailboxInfo);
            if (requestType === 'Default') {
                // most request types do not need to be proxied back to main so short circuit those on the
                // worker thread with the default options that don't require access to multi-account UX state
                return {
                    ...getRequestOptionsOrDefault(requestOptions),
                    ...requestOptions,
                };
            } else {
                // requests to non-default mailboxes like connected accounts and shared mailboxes need to
                // be proxied to the main thread for setting routing hints, auth tokens, etc.
                const basicOptions = toBasicOptions(requestOptions);
                const preparedBasicOptions = await prepareCb(basicOptions);
                const preparedOptions = preparedBasicOptions
                    ? fromBasicOptions(preparedBasicOptions)
                    : {};

                if (!preparedOptions || !requestOptions) {
                    return preparedOptions;
                } else {
                    return {
                        ...requestOptions,
                        ...preparedOptions,
                    };
                }
            }
        }
    };

    const getMsalToken = async (
        isConsumer: boolean,
        resource?: string,
        mailboxInfo?: MailboxInfo,
        scope?: string
    ) => {
        return getCachedData<TokenResponse | undefined>(
            'msalToken',
            { resource, mailboxInfo, scope },
            () => getMsalTokenCb(isConsumer, resource, sanitize(mailboxInfo), scope),
            ServiceConfigCacheBehavior.cacheAndProxy
        );
    };

    const getAuthToken = async (
        headers: HeadersWithoutIterator | undefined,
        mailboxInfo?: MailboxInfo
    ) => {
        return getCachedData<string | undefined>(
            'authToken',
            { mailboxInfo },
            () => {
                const basicHeaders = toBasicHeaders(headers as Headers);
                return getAuthTokenCb(basicHeaders, sanitize(mailboxInfo));
            },
            ServiceConfigCacheBehavior.cacheAndProxy
        );
    };

    const getResourceToken = async (
        resource: string,
        wwwAuthenticateHeader?: string,
        mailboxInfo?: MailboxInfo,
        scope?: string,
        childAppId?: string,
        allowPrompt?: boolean,
        claims?: string
    ) => {
        return getCachedData<TokenResponse | undefined>(
            'resourceToken',
            { mailboxInfo, resource, scope },
            () => {
                return getResourceTokenCb(
                    resource,
                    wwwAuthenticateHeader,
                    sanitize(mailboxInfo),
                    scope,
                    childAppId,
                    allowPrompt,
                    claims
                );
            },
            ServiceConfigCacheBehavior.cacheAndProxy
        );
    };

    const getUserToken = async (
        headers: HeadersWithoutIterator | undefined,
        mailboxInfo?: MailboxInfo
    ) => {
        return getCachedData<string | undefined>(
            'userToken',
            { mailboxInfo },
            () => {
                const basicHeaders = toBasicHeaders(headers as Headers);
                return getUserTokenCb(basicHeaders, sanitize(mailboxInfo));
            },
            ServiceConfigCacheBehavior.cacheAndProxy
        );
    };

    const getAnchorMailbox = getAnchorMailboxCb
        ? async (mailboxInfo?: MailboxInfo) => {
              return getCachedData<string | undefined>(
                  'anchorMailbox',
                  { mailboxInfo },
                  () => getAnchorMailboxCb(sanitize(mailboxInfo)),
                  ServiceConfigCacheBehavior.cacheOnly
              );
          }
        : undefined;

    const getExplicitLogon = getExplicitLogonCb
        ? async (mailboxInfo?: MailboxInfo) => {
              return getCachedData<string | undefined>(
                  'explicitLogon',
                  { mailboxInfo },
                  () => getExplicitLogonCb(sanitize(mailboxInfo)),
                  ServiceConfigCacheBehavior.cacheOnly
              );
          }
        : undefined;

    const getWebSessionType = async (mailboxInfo?: MailboxInfo) => {
        return (
            (await getCachedData<string>(
                'webSessionType',
                { mailboxInfo },
                () => getWebSessionTypeCb(sanitize(mailboxInfo)),
                ServiceConfigCacheBehavior.cacheOnly
            )) || ''
        );
    };

    const throttledUpdateRequestSettings = throttle(updateRequestSettings, 2000);
    const getCanaryHeaders = () => {
        // return the current value of the canary/request data immediately, but refresh the settings periodically
        throttledUpdateRequestSettings();
        return getCanaryHeadersImpl();
    };

    updateServiceConfig({
        ...config,
        getAuthToken,
        getResourceToken,
        getUserToken,
        getWebSessionType,
        getMsalToken,
        getAnchorMailbox,
        getExplicitLogon,
        onAuthFailed: (headers, mailboxInfo) => {
            getTokenCache()?.clearCacheForMailboxInfo(mailboxInfo);
            clearServiceConfigData();
            onAuthFailedCb(getDiagnostics(headers), sanitize(mailboxInfo));
        },
        isFeatureEnabled: (s: GlobalFeatureName, mailboxInfo?: MailboxInfo) =>
            isFeatureEnabled(s, mailboxInfo, true),
        isAccountFeatureEnabled: (s: AccountFeatureName, mailboxInfo: MailboxInfo) =>
            isAccountFeatureEnabled(s, mailboxInfo, true),
        isUserIdle: () => !!isUserIdle(),
        prepareRequestOptions: prepareRequestOptionsCb ? proxyPrepareRequestOptions : undefined,
        getCanaryHeaders,
        onActivityTimeoutError: () => {
            return onActivityTimeoutErrorCb();
        },
        onPinnedAppsChanged: (mailboxInfo: MailboxInfo, newSetting: string) => {
            onPinnedAppsChangedCb(sanitize(mailboxInfo), newSetting);
        },
        getModuleContextMailboxInfo: (): Promise<MailboxInfo> => {
            return getModuleContextMailboxInfoCb();
        },
        getApplicationSettings: <TGroup extends ApplicationSettingGroup>(
            group: TGroup,
            mailboxInfo?: MailboxInfo
        ) => getApplicationSettings(group, mailboxInfo),
    });
}

function initWorkerConfig(
    prepareConnectedAccountRequestOptionsCb: PrepareConnectedAccountRequestOptionsCb | undefined,
    getTimeZoneAndLanguageCb: GetTimeZoneAndLanguageCb
) {
    const prepareConnectedAccountRequestOptions = async (
        mailboxRequestOptions: MailboxRequestOptions
    ): Promise<RequestOptions> => {
        if (prepareConnectedAccountRequestOptionsCb) {
            return fromBasicOptions(
                await prepareConnectedAccountRequestOptionsCb(toBasicOptions(mailboxRequestOptions))
            );
        }

        // No callback provided, return the original options
        return mailboxRequestOptions;
    };

    const getTimeZoneAndLanguage = async () => {
        return !!getTimeZoneAndLanguageCb ? getTimeZoneAndLanguageCb() : undefined;
    };

    updateWorkerConfig({
        prepareConnectedAccountRequestOptions,
        getTimeZoneAndLanguage,
    });
}

async function getCachedData<T>(
    dataKey: keyof ServiceConfigData,
    cacheKey: Partial<ServiceConfigDataCacheKey>,
    cb: () => Promise<T>,
    behavior: ServiceConfigCacheBehavior
) {
    let data: T | undefined;

    if (!isMailboxInfoDefined(cacheKey)) {
        // don't use the cache for default mailboxinfo
        data = await cb();
    } else {
        const serviceConfigData = getServiceConfigData(cacheKey)?.[dataKey];
        if (serviceConfigData === undefined) {
            data = await cb();
            updateServiceConfigData(cacheKey, { [dataKey]: data }, [dataKey]);
        } else if (serviceConfigData === undefinedSymbol) {
            data = undefined;
        } else {
            data = serviceConfigData as T;
            if (behavior == ServiceConfigCacheBehavior.cacheAndProxy) {
                // lazy update the cache without blocking the cache read / data return
                self.setTimeout(() => {
                    cb().then(updatedValue => {
                        updateServiceConfigData(cacheKey, { [dataKey]: updatedValue }, [dataKey]);
                    });
                });
            }
        }
    }

    return data;
}

function getDiagnostics(headers?: HeadersWithoutIterator): BasicHeader[] {
    const rv: BasicHeader[] = [];
    for (const key of diagnosticHeaders) {
        const value = headers?.get(key);
        if (value) {
            rv.push({ key, value });
        }
    }

    return rv;
}

function isMailboxInfoDefined(
    cacheKey: Partial<ServiceConfigDataCacheKey>
): cacheKey is ServiceConfigDataCacheKey {
    return !!cacheKey.mailboxInfo;
}
