import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getDatabase } from 'owa-offline-database';
import { loadSetting } from 'owa-offline-settings';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';
export type FolderPaneBitFlagsMasks = any;

export const getIsCustomSortEnabledOffline = async (mailboxInfo: MailboxInfo) => {
    if (isSharedCoprincipalAccountEnabled() && isMailboxSharedOrDelegateUserMailbox(mailboxInfo)) {
        // Custom sorting is not supported for shared coprincipal accounts
        return false;
    }

    // In MA mode, we use the global account for the custom sort vs alphabetization setting
    const database = getDatabase();
    const userConfig = await loadSetting<OwaUserConfiguration>(database, 'userConfig');
    const folderPaneBitFlags = userConfig?.UserOptions?.FolderPaneBitFlags || 0;

    const isBitSet = !!(1 & folderPaneBitFlags);
    const isExplicitLogon = userConfig?.SessionSettings?.IsExplicitLogon;

    const isCustomOrderOn = isFeatureEnabled('fwk-folders-custom-sorted-on-boot', mailboxInfo);
    return !isExplicitLogon && isCustomOrderOn && !isBitSet;
};
