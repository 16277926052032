import type { CustomDataMap } from 'owa-analytics-types';
import { errorThatWillCauseAlert } from 'owa-trace';
import { scrubForPii } from 'owa-config/lib/scrubForPii';

const htmlRegex = new RegExp(/<((div)|(span)|(table)|(ul)|(body))>/);

export function safeStringify(input: any): string {
    try {
        return JSON.stringify(input);
    } catch (e) {
        return e.message;
    }
}

function cleanUp(s: string = ''): string {
    // ADAL passes "access_token" object (no actual access token inside) in the error message and it makes PrivacyGuard to report it
    if (s.indexOf('AdalUiRequiredException') !== -1) {
        s = s.replace('access_token', 'accessToken');
    }
    return s;
}

export function validatePropertyBag(
    propertyBag: CustomDataMap | undefined,
    eventName: string,
    turnOffCustomData: boolean
): string {
    if (turnOffCustomData) {
        return 'emergency flight on';
    }

    if (propertyBag) {
        var keys = Object.keys(propertyBag);
        for (var ii = 0; ii < keys.length; ii++) {
            var propertyKey = keys[ii];
            var propertyValue = propertyBag[propertyKey];
            var errorMessage: string | null = null;
            if (
                propertyKey.toLowerCase().indexOf('subject') > -1 &&
                typeof propertyValue == 'string'
            ) {
                errorMessage = "'s key contains subject";
            } else if (propertyValue) {
                if (typeof propertyValue == 'object' && !(propertyValue instanceof Date)) {
                    errorMessage = ' is an object';
                } else if (htmlRegex.test(propertyValue.toString())) {
                    errorMessage = ' contains html';
                }
            }

            if (errorMessage) {
                /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
                 * The error name (message) must be a string literal (no variables in it).
                 *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
                errorThatWillCauseAlert(
                    propertyKey + ' of datapoint ' + eventName + errorMessage + ' which is pii'
                );
                return errorMessage;
            }
            if (typeof propertyValue == 'string') {
                propertyBag[propertyKey] = scrubForPii(propertyValue);
            }
        }
    }

    return cleanUp(safeStringify(propertyBag));
}
