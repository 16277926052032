import type {
    QueuedActionResult,
    AcceptedQueuedResult,
    RejectedQueuedResult,
} from 'owa-data-worker-utils';
import { assertNever } from 'owa-assert';
import {
    NO_ID_CHANGES,
    defaultResponseProcessor,
} from '../resultProcessors/defaultResultProcessor';
import { createItemProcessor } from '../resultProcessors/createItemResultProcessor';
import { sendItemProcessor } from '../resultProcessors/sendItemResultProcessor';
import { copyMoveItemOrConversationResultProcessor } from '../resultProcessors/copyMoveItemOrConversationResultProcessor';
import { createFolderResultProcessor } from '../resultProcessors/createFolderResultProcessor';
import { errorThatWillCauseAlert, trace } from 'owa-trace';
import { type QueuedAction } from './QueuedAction';

export type QueuedActionResultProcessor = (
    action: Omit<QueuedAction, 'id'>,
    queuedActionResult: QueuedActionResult
) => Promise<AcceptedQueuedResult | RejectedQueuedResult>;

export const processResult: QueuedActionResultProcessor = async (
    action: Omit<QueuedAction, 'id'>,
    result: QueuedActionResult
): Promise<AcceptedQueuedResult | RejectedQueuedResult> => {
    let rv: AcceptedQueuedResult | RejectedQueuedResult;

    try {
        switch (action.resultProccesor) {
            case 'Default': {
                rv = await defaultResponseProcessor(action, result);
                break;
            }
            case 'CreateItem': {
                rv = await createItemProcessor(action, result);
                break;
            }
            case 'SendItem': {
                rv = await sendItemProcessor(action, result);
                break;
            }
            case 'CopyMoveItemOrConversation': {
                rv = await copyMoveItemOrConversationResultProcessor(action, result);
                break;
            }
            case 'CreateFolder': {
                rv = await createFolderResultProcessor(action, result);
                break;
            }
            default:
                assertNever(action.resultProccesor);
        }
    } catch (e) {
        const msg = `${action.opName} ${action.uuid} failed to process result ${e.message}.`;
        trace.warn(msg, 'actionQueue');
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert(msg, e);
        rv = {
            fetchError: e,
            idChanges: NO_ID_CHANGES,
        };
    }

    return rv;
};

export function isResultAccepted(
    result: AcceptedQueuedResult | RejectedQueuedResult
): result is AcceptedQueuedResult {
    return (result as RejectedQueuedResult).rejectCode === undefined;
}
