import type { AppDatabase } from 'owa-offline-database';
import { setTransactionSource } from 'owa-offline-database';
import Dexie from 'dexie';
import type { SyncModuleState } from 'owa-offline-sync-state-schema';

export function saveSyncState(database: AppDatabase, syncState: SyncModuleState<any>) {
    return database.transaction('rw', database.syncState, async () => {
        setTransactionSource(Dexie.currentTransaction, 'sync');
        return database.syncState.put(syncState);
    });
}
