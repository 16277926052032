import { setTransactionSource } from 'owa-offline-database';
import type { AppDatabase } from 'owa-offline-database';
import Dexie from 'dexie';
import type { SyncSetting } from 'owa-offline-settings-schema';

export function saveSetting(database: AppDatabase, name: string, data: any): Promise<any> {
    return database.transaction('rw', database.settings, () => {
        setTransactionSource(Dexie.currentTransaction, 'sync');

        return database.settings.put({ name, data });
    });
}

export function saveSettings<T>(database: AppDatabase, settings: SyncSetting<T>[]): Promise<any> {
    return database.transaction('rw', database.settings, () => {
        setTransactionSource(Dexie.currentTransaction, 'sync');

        return database.settings.bulkPut(settings);
    });
}
