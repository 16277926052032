import Dexie, { type Transaction, type Table } from 'dexie';

import type { AppDatabase, Change, ChangeCallback } from './database';
import { type ChangeType } from './database';
import { emitSyncEvent } from 'owa-offline-sync-diagnostics';
import { PendingIdbChanges } from './PendingIdbChanges';
import { isRunningOnWorker } from 'owa-config';
import { MESSAGE_BODIES_TABLE_NAME } from 'owa-offline-message-bodies-schema';

export type TransactionSource = 'sync' | 'cleanup' | 'localLie';

// Transaction source is documented but missing from the typing
export interface TransactionWithSource extends Transaction {
    source?: TransactionSource;
}

// Suppress change notifications for message bodies since they are large and we don't need such notifications.
export function doesTableSupportChangeNotifications(table: Table) {
    return table.name !== MESSAGE_BODIES_TABLE_NAME;
}

export function initializeIdbChangeNotifications(
    database: Dexie,
    callback: ChangeCallback<any, any>
) {
    let channel: BroadcastChannel | undefined;
    // We broadcast changes made on any thread, but only notify about changes on worker threads.
    if (self.BroadcastChannel) {
        channel = new BroadcastChannel(getBroadcastChannelName(database));

        if (isRunningOnWorker()) {
            channel.onmessage = ev => callback(ev.data);
        }
    }
    const broadcast = (change: Change<any, any>) => {
        setTimeout(() => {
            if (isRunningOnWorker()) {
                callback(change);
            }
            channel?.postMessage(change);
        }, 0);
    };
    const pendingIdbChanges = new PendingIdbChanges(broadcast);
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    database.tables.forEach(table => {
        table.hook('creating').subscribe(getCreatingHook(table, pendingIdbChanges));
        table.hook('updating').subscribe(getUpdatingHook(table, pendingIdbChanges));
        table.hook('deleting').subscribe(getDeletingHook(table, pendingIdbChanges));
    });
}

function getBroadcastChannelName(database: Dexie) {
    return `idb_changes_${database.name}`;
}

// Set the transaction source on the outermost transaction to help decide
// whether we should send change notifications for this transaction
export function setTransactionSource(trans: Transaction, source: TransactionSource) {
    let outer = trans as TransactionWithSource;
    while (outer.parent) {
        outer = outer.parent;
    }

    if (outer.source !== 'cleanup') {
        outer.source = source;
    }
}

/**
 * Should this transaction send change notifications.
 * */
function shouldSendNotifications(trans?: TransactionWithSource) {
    // Check whether this transaction has sync source set or
    // is nested beneath such a transaction.
    while (trans?.parent) {
        trans = trans.parent;
    }

    switch (trans?.source) {
        case 'sync':
        case 'localLie':
            return true;
        default:
            return false;
    }
}

function getCreatingHook(table: Table, pendingIdbChanges: PendingIdbChanges) {
    return (primKey: any, obj: any, trans: TransactionWithSource) => {
        // We don't try to handle missing auto-incremented primary key here, but we don't use those
        if (shouldSendNotifications(trans) && doesTableSupportChangeNotifications(table)) {
            pendingIdbChanges.addPendingChange(trans, {
                table: table.name,
                type: 0,
                key: primKey,
                obj,
                workerTime: Date.now(),
            });
        }
    };
}

function getUpdatingHook(table: Table, pendingIdbChanges: PendingIdbChanges) {
    return (
        mods: {
            [keyPath: string]: any | undefined;
        },
        primKey: any,
        oldObj: any,
        trans: TransactionWithSource
    ) => {
        if (shouldSendNotifications(trans) && doesTableSupportChangeNotifications(table)) {
            // Structured cloning when we post in BroadcastChannek will drop a property with undefined value
            // so convert to null instead so those modifications don't get lost.
            const modsWithoutUndefined: {
                [keyPath: string]: any;
            } = {};
            const newObj = Dexie.deepClone(oldObj);
            let hasChanges = false;
            for (const propPath of Object.keys(mods)) {
                var mod = mods[propPath];
                if (typeof mod === 'undefined') {
                    Dexie.delByKeyPath(newObj, propPath);
                    modsWithoutUndefined[propPath] = null;
                    hasChanges = true;
                } else {
                    // Dexie always thinks arrays have changed. So double check
                    const oldValue = Dexie.getByKeyPath(oldObj, propPath);
                    if (JSON.stringify(oldValue) !== JSON.stringify(mod)) {
                        Dexie.setByKeyPath(newObj, propPath, mod);
                        modsWithoutUndefined[propPath] = mod;
                        hasChanges = true;
                    }
                }
            }
            // Don't notify unless the object is actually changing
            if (hasChanges) {
                pendingIdbChanges.addPendingChange(trans, {
                    table: table.name,
                    type: 1,
                    key: primKey,
                    obj: newObj,
                    oldObj,
                    mods: modsWithoutUndefined,
                    workerTime: Date.now(),
                });
            }
        }
    };
}

function getDeletingHook(table: Table, pendingIdbChanges: PendingIdbChanges) {
    return (primKey: any, obj: any, trans: TransactionWithSource) => {
        if (shouldSendNotifications(trans) && doesTableSupportChangeNotifications(table)) {
            // We skip notifying on no-op deletes where the item doesn't exist.
            if (obj) {
                pendingIdbChanges.addPendingChange(trans, {
                    table: table.name,
                    type: 2,
                    key: primKey,
                    oldObj: obj,
                    workerTime: Date.now(),
                });
            } else {
                emitSyncEvent(
                    'notifyIdbChange: skipping notifying no-op deletion',
                    { key: primKey },
                    (table.db as AppDatabase).mailboxInfo
                );
            }
        }
    };
}
