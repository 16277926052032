import type { AppDatabase } from 'owa-offline-database';
import { isCorruptDbError } from 'owa-offline-errors';
import { handleDatabaseCorruption } from './handleDatabaseCorruption';

/**
 * Check to see if the error we are seeing is due to db corruption and if so, try to recover from it.
 *
 * @param database The db seeing an error
 * @param e The error we to be checked to see if it indicates db corruption
 * @param isCritical Whether the error is being seen in a critical spot like message list sync or calendar surface sync
 * @returns
 */
export function checkAndHandleDatabaseCorruption(
    database: AppDatabase,
    e: Error,
    isCritical: boolean = false
): boolean {
    const isCorruption = isCorruptDbError(e);
    if (isCorruption) {
        handleDatabaseCorruption(database, e, isCritical);
    }
    return isCorruption;
}
